/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import {
  useLazyGetEventDocumentSfdtQuery,
  usePostDeleteEventDocumentMutation,
} from 'store/api'
import { isValidString } from 'helpers/isValidString'
import { useAppDispatch } from 'store/hooks'
import {
  setAttachment,
  setCourriers,
} from 'store/slices/courrierSlice'
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  CardContent,
} from '@mui/material'
import { toast } from 'react-toastify'
import DocumentStatusButton from 'layouts/TravelerLayout/DocumentsModal/DocumentStatusButton/DocumentStatusButton'
import VignetteRenderer from 'components/MediaRenderer/VignetteRenderer'

/* Type imports ------------------------------------------------------------- */
import { TypePJ } from 'API/__generated__/Api'
import type { PieceJointe } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const CardContainer = styled(Card)`
  margin-top: 20px;
  margin-bottom: 20px;
  border: solid 2px ${(props) => props.theme.palette.primary.main} !important;
  padding: 20px;
`

const CardContentContainer = styled(CardContent)`
  display: flex;
  padding: 10px !important;
  height: 100%;
`

const DocumentContainer = styled.div`
  width: 100%;
  display: grid;
  margin-left:  10px;
  grid-template-columns: 1fr;
  align-items: stretch;
  justify-content: stretch;
`

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const ButtonContainer = styled.div`
  height: min-content;
  align-self: end;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  align-items: stretch;
  justify-content: stretch;
`

const DocumentTypeContainer = styled.div`
  font-size: 16px;
  font-weight: bold;
`

const DocumentDateContainer = styled.div`
  font-size: 12px;
`

/* Component declaration ---------------------------------------------------- */
interface DocumentListProps {
  document: PieceJointe;
  caseId: string;
  isConnected: boolean;
}

const DocumentList: React.FC<DocumentListProps> = ({
  document,
  caseId,
  isConnected,
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [
    deleteDocument,
  ] = usePostDeleteEventDocumentMutation()
  const [
    getEventDocumentSfdt,
    { isFetching: isFetchingEventDocumentSfdt },
  ] = useLazyGetEventDocumentSfdtQuery()

  const onDeleteClick = () => {
    deleteDocument({ id: document.id || '', typePJ: document.type }).catch(console.error)
  }

  const onViewClick = () => {
    if (isValidString(document.url)) {
      window.open(document.url || '', '_blank', 'noreferrer')
    }
  }

  const onEditClick = () => {
    if (!document.id) return

    getEventDocumentSfdt(document.id)
      .then(({ data, isError }) => {
        if (data?.jsonData && !isError) {
          dispatch(setCourriers([ { ...data, docId: document.id! } ]))
          navigate(`/dossiers/${caseId}/traveller/actions/document`)
        } else {
          toast.error(`Une erreur est survenue lors du chargement du document.`)
        }
      })
      .catch(console.error)
  }

  const onSendClick = () => {
    dispatch(setAttachment([ document ]))
    navigate(`/dossiers/${caseId}/traveller/actions/communication`)
  }

  const disabled = useMemo(() => !isConnected || isFetchingEventDocumentSfdt, [ isConnected, isFetchingEventDocumentSfdt ])

  return (
    <CardContainer>
      <CardContentContainer>
        <VignetteRenderer
          document={document}
          height="auto"
          width="100px"
        />
        <DocumentContainer>
          <DescriptionContainer>
            <div>
              <DocumentTypeContainer>
                {document.libelle}
              </DocumentTypeContainer>
              <DocumentDateContainer>
                {
                  `Dernière modification le ${DateUtils.APIStrToLocalDateString(document.dateEdition)} à \
                    ${DateUtils.APIStrToLocalTimeString(document.dateEdition, { second: undefined, hour: '2-digit', minute: '2-digit' })}`
                }
              </DocumentDateContainer>
            </div>
            <DocumentStatusButton
              status={document.type}
              disabled={disabled}
            />
          </DescriptionContainer>
          <ButtonContainer>
            <Button
              variant="outlined"
              fullWidth
              disabled={disabled || document.type !== TypePJ.DocEvenementielBrouillon}
              onClick={onDeleteClick}
            >
              Supprimer
            </Button>
            <Button
              variant="outlined"
              fullWidth
              // disabled={disabled || document.type !== TypePJ.DocEvenementiel}
              disabled
            >
              Signer
            </Button>
            <Button
              variant="outlined"
              fullWidth
              disabled={disabled || document.type !== TypePJ.DocEvenementiel}
              onClick={onSendClick}
            >
              Envoyer
            </Button>
            <Button
              variant="contained"
              fullWidth
              disabled={document.type === TypePJ.DocEvenementiel ? false : disabled}
              onClick={() => document.type === TypePJ.DocEvenementiel ? onViewClick() : onEditClick()}
            >
              {document.type === TypePJ.DocEvenementiel ? 'Voir' : 'Modifier'}
            </Button>
          </ButtonContainer>
        </DocumentContainer>
      </CardContentContainer>
    </CardContainer>
  )
}

export default DocumentList
