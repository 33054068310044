/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useOutletContext,
  useParams,
} from 'react-router-dom'
import {
  useGetApplicationGarantieListQuery,
  useGetCaseInfosQuery,
  useGetCompensationDamagePositionListQuery,
  useGetNatureDetailBienListQuery,
  useGetSinappsCompensationModeListQuery,
  useGetSinappsGarantieListQuery,
} from 'store/api'
import { calculateAllCompensations } from 'services/CompensationService'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'

/* Component imports -------------------------------------------------------- */
import { MenuItem } from '@mui/material'
import { Field } from 'formik'
import { Select } from 'formik-mui'
import Tabs from 'components/Tabs/Tabs'
import Loader from 'components/Loader/Loader'
import TravelerLargeTitle from 'components/TravelerLargeTitle/TravelerLargeTitle'
import TabPanel from 'components/TabPanel/TabPanel'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CompensationPerson from './CompensationPerson/CompensationPerson'

/* Type imports ------------------------------------------------------------- */
import type {
  Garantie,
  CodeLabel,
  ActeurTraveller,
} from 'API/__generated__/Api'
import type { TravelerForm } from 'types/TravelerForm'
import { isValidString } from 'helpers/isValidString'

/* Styled components -------------------------------------------------------- */
const FormContainer = styled.div`
  padding-bottom: 40px;
`

const Container = styled.div`
  margin-top: -20px;
  margin-bottom: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface CompensationPageProps {}

const CompensationPage: React.FC<CompensationPageProps> = () => {
  const formikForm = useOutletContext<TravelerForm>()
  const { caseId = '' } = useParams<{caseId: string}>()
  const [ tabValue, setTabValue ] = useState<number>(0)
  const [ updateCompensations, setUpdateCompensations ] = useState<number>(0)

  const {
    currentData: caseInfos,
    isFetching: isFetchingCaseInfos,
  } = useGetCaseInfosQuery(caseId)
  const {
    currentData: compensationDamagePositionList = [],
    isFetching: isFetchingCompensationDamagePositionList,
  } = useGetCompensationDamagePositionListQuery()
  const {
    currentData: garantieList = [],
    isFetching: isFetchingGarantieList,
  } = useGetApplicationGarantieListQuery()
  const {
    currentData: sinappsGarantieList = [],
    isFetching: isFetchingSinappsGarantieList,
  } = useGetSinappsGarantieListQuery(caseId)
  const {
    currentData: compensationModeList = [],
    isFetching: isFetchingcompensationModeList,
  } = useGetSinappsCompensationModeListQuery()
  const {
    currentData: natureDetailBien = [],
    isFetching: isFetchingNatureDetailBien,
  } = useGetNatureDetailBienListQuery(caseId)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number): void => {
    setTabValue(newValue)
  }

  const handleValue = (valueType: string, value?: string | Garantie[] | CodeLabel | number, newGaranties?: Garantie[]) => {
    // reset indemnisation garanties if garantie code has changed
    if (newGaranties) {
      formikForm.values.acteurs.forEach((acteur, acteurIndex) =>
        acteur.indemnisation?.indemnisations?.forEach((indemnisation, indemnisationIndex): void => {
          if (!newGaranties.find(
            (garantie) => garantie.code.code === indemnisation.garantie?.code ||
            (value as CodeLabel)?.code === indemnisation.garantie?.code)
          ) {
            formikForm.setFieldValue(`acteurs[${acteurIndex}].indemnisation.indemnisations[${indemnisationIndex}].garantie`, undefined)
          }
        }),
      )
    }
    formikForm.setFieldValue(valueType, value)
    setUpdateCompensations(updateCompensations + 1)
  }

  useEffect(() => {
    if (updateCompensations === 0) return

    const timer = setTimeout(() => {
      if (!formikForm.values.disabled && formikForm.values.acteurs) {
        const data = structuredClone(formikForm.values)

        data.acteurs = data.acteurs.map((person) => {
          return ({
            ...person,
            indemnisation: calculateAllCompensations({
              damages: [
                ...person.pieces?.flatMap((room) => room.dommagesImmobilierEmbellissement) || [],
                ...person.dommagesMobilierDivers || [],
              ],
              natureDetailBien,
              isSinapps: caseInfos?.mission.origine?.code === 'SIN',
              oldCompensation: person.indemnisation,
            }),
          })
        })

        formikForm.setValues({
          ...formikForm.values,
          ...data,
        })
      }
    }, 2000)

    return () => clearTimeout(timer)
  }, [ updateCompensations ])

  const getTabs = (): string [] => {
    if (formikForm.values.acteurs !== undefined && formikForm.values.acteurs.length > 0) {
      return [
        ...formikForm.values.acteurs.filter((person) => !person.isDeleted).map(
          (person: ActeurTraveller, index: number): string => {
            return person.nom ?? `Personne ${index + 1}`
          },
        ),
      ]
    }

    return []
  }

  if (formikForm.values.acteurs === undefined) {
    return null
  }

  const findPersonIndex = (filteredIndex: number) => {
    return formikForm.values.acteurs.findIndex((person) =>
      formikForm.values.acteurs?.filter((person) => !person.isDeleted)[filteredIndex].id === person.id,
    )
  }

  return (
    <FormContainer>
      {
        (
          isFetchingGarantieList ||
          isFetchingCompensationDamagePositionList ||
          isFetchingSinappsGarantieList ||
          isFetchingCaseInfos ||
          isFetchingNatureDetailBien ||
          isFetchingcompensationModeList
        ) ?
          <Loader /> :
          <>
            <TravelerLargeTitle>
              Indemnisation
            </TravelerLargeTitle>
            {
              caseInfos?.mission.origine?.code === 'SIN' &&
                <Container>
                  <FormBoldTitle bigger>
                    Mode d'indemnisation
                  </FormBoldTitle>
                  <Field
                    name="modeIndemnisationAssure.code"
                    component={Select}
                    displayEmpty
                    size="small"
                    value={isValidString(formikForm.values?.modeIndemnisationAssure?.code) ? formikForm.values?.modeIndemnisationAssure?.code : ''}
                    renderValue={verifySelectFieldValue(formikForm.values?.modeIndemnisationAssure?.code)}
                    disabled={formikForm.values.disabled}
                  >
                    {
                      compensationModeList.map((mode, index) => (
                        <MenuItem
                          value={mode.code}
                          key={`${mode.code}-${index}`}
                        >
                          {mode.libelle}
                        </MenuItem>
                      ))
                    }
                  </Field>
                </Container>
            }
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              tabs={getTabs()}
            />
            {
              formikForm.values.acteurs?.filter((person) => !person.isDeleted)?.map((person, index) => (
                <TabPanel
                  value={tabValue}
                  index={index}
                  key={`${person.id}-${index}`}
                >
                  <CompensationPerson
                    indemnisation={person.indemnisation}
                    garanties={formikForm.values.garanties || []}
                    handleValue={handleValue}
                    personIndexName={`acteurs[${findPersonIndex(index)}].indemnisation`}
                    compensationDamagePositionList={compensationDamagePositionList}
                    garantieList={garantieList}
                    sinappsGarantieList={sinappsGarantieList}
                    disabled={formikForm.values.disabled}
                    isSinapps={caseInfos?.mission.origine?.code === 'SIN'}
                  />
                </TabPanel>
              ))
            }
          </>
      }
    </FormContainer>
  )
}

export default CompensationPage
