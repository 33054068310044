/* Type declarations -------------------------------------------------------- */
export enum TypeDommageSinapps {
  Dommage = 'Dommage',
  Bien = 'Bien',
}

export interface Dommage {
  codeNature: string;
  codeDetail: string;
  codeNatureSinapps: string;
  codeDetailSinapps: string;
  idDetailDommageSinapps: string;
  typeBien?: string;
  libelle: string;
  typeReparation: string;
  quantite: number;
  prixUnitaire: number;
  age?: number;
  tauxVetuste: number;
  tauxTva: number;
  montantHT: number;
  montantTva: number;
  montantTTC: number;
  montantVetuste: number;
  montantVetusteRecuperable: number;
  montantVetusteDeduite: number;
  dateAchat?: string;
  typeDommageSinapps?: TypeDommageSinapps;
  justification: string;
  reparation?: string;
}
