/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import {
  Badge,
  Tooltip,
} from '@mui/material'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import TravelerAttachmentButton from './TravelerAttachmentButton'

/* Type imports ------------------------------------------------------------- */
import type { ButtonProps } from '@mui/material'
import type { TravelerButtonProperties } from 'types/TravelerButton'

/* Styled components -------------------------------------------------------- */
const TravelerIconButton = styled.div`
  margin-left: 13px;
  margin-right: 0px;

  button {
    margin-left: 0;
    margin-right: 0;
  }

  span {
    font-weight: bold;
  }
`

/* Component declaration ---------------------------------------------------- */
interface TravelerButtonProps extends ButtonProps {
  caseId: string;
  buttonProperty: TravelerButtonProperties;
  isConnected: boolean;
}

const TravelerButton: React.FC<TravelerButtonProps> = ({ caseId, buttonProperty, isConnected }) => {

  return (
    <TravelerIconButton>
      <Tooltip
        title={buttonProperty.tooltip}
        arrow
        placement="top"
      >
        <Badge
          badgeContent={buttonProperty.badge}
          invisible={buttonProperty.badge === -1}
          color="primary"
          anchorOrigin={
            {
              vertical: 'top',
              horizontal: 'left',
            }
          }
        >
          {
            buttonProperty.buttonType === 'attachment' ?
              <TravelerAttachmentButton
                Icon={buttonProperty.icon}
                caseId={caseId}
                disabled={buttonProperty.disable && !isConnected}
              /> :
              <CustomIconButton
                Icon={buttonProperty.icon}
                color="primary"
                onClick={() => buttonProperty.action()}
                variant={buttonProperty.variant}
                disabled={buttonProperty.disable && !isConnected}
              />
          }
        </Badge>
      </Tooltip>
    </TravelerIconButton>
  )
}

export default TravelerButton
