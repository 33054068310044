/* Module imports ----------------------------------------------------------- */
import {
  roundValues,
  valueOrZero,
} from './IndemnisationSinnapsHelper'

/* Type imports ------------------------------------------------------------- */
import type { DommageSinapps } from './types/DommageSinapps'
import type { DommageBienSinapps } from './types/DommageBienSinapps'
import type {
  IndemnisationSinapps,
  IndemnisationSinappsExistante,
} from './types/IndemnisationSinapps'

/* Function declaration ----------------------------------------------------- */
const calculIndemniteSinapps = (indemn: IndemnisationSinapps, existingIndemnisationsSinapps: IndemnisationSinappsExistante[]): IndemnisationSinapps => {
  const existingIndemnisation = existingIndemnisationsSinapps.find((i) => i.idGarantie === indemn.idGarantie)
  // On récupère les données déjà saisies
  indemn.montantFranchiseTTC = existingIndemnisation ? existingIndemnisation.montantFranchiseTTC : 0
  indemn.montantFranchiseHT = existingIndemnisation ? existingIndemnisation.montantFranchiseHT : 0
  indemn.tauxPenalite = existingIndemnisation ? existingIndemnisation.tauxPenalite : 0

  // Pénalité
  if (indemn.tauxPenalite) {
    indemn.montantPenaliteImmediateHT = indemn.montantIndemniteImmediateHTAvant * (indemn.tauxPenalite / 100)
    indemn.montantPenaliteImmediateTTC = indemn.montantIndemniteImmediateTTCAvant * (indemn.tauxPenalite / 100)
    indemn.montantPenaliteDiffereeHT = indemn.montantIndemniteDiffereeHTAvant * (indemn.tauxPenalite / 100)
    indemn.montantPenaliteDiffereeTTC = indemn.montantIndemniteDiffereeTTCAvant * (indemn.tauxPenalite / 100)
  }

  // Franchise (Elle n'est ni HT ni TTC mais sa répercution sur le HT ou TTC peut être différente)
  const montantImmediat = indemn.montantIndemniteImmediateHTAvant - valueOrZero(indemn.montantPenaliteImmediateHT)
  const montantDiffere = indemn.montantIndemniteDiffereeHTAvant - valueOrZero(indemn.montantPenaliteDiffereeHT)

  // HT
  if (indemn.montantFranchiseHT) {
    if (montantImmediat + montantDiffere < indemn.montantFranchiseHT) {
      indemn.montantFranchiseImmediateHT = montantImmediat
      indemn.montantFranchiseDiffereeHT = montantDiffere
    }
    else {
      if (montantImmediat > indemn.montantFranchiseHT) {
        indemn.montantFranchiseImmediateHT = indemn.montantFranchiseHT
        indemn.montantFranchiseDiffereeHT = 0
      } else {
        const franchiseInitiale = indemn.montantFranchiseHT - montantDiffere
        indemn.montantFranchiseImmediateHT = montantImmediat
        if (montantDiffere > franchiseInitiale) {
          indemn.montantFranchiseDiffereeHT = franchiseInitiale
        } else {
          indemn.montantFranchiseDiffereeHT = 0
        }
      }
    }
  }

  // Calcul de l'indemnité après pénalité et franchise
  // Immédiat
  if (indemn.montantFranchiseImmediateHT) {
    indemn.montantIndemniteImmediateHTApres = montantImmediat - indemn.montantFranchiseImmediateHT
  } else {
    indemn.montantIndemniteImmediateHTApres = montantImmediat
  }
  // Différé
  if (indemn.montantFranchiseDiffereeHT) {
    indemn.montantIndemniteDiffereeHTApres = montantDiffere - indemn.montantFranchiseDiffereeHT
  } else {
    indemn.montantIndemniteDiffereeHTApres = montantDiffere
  }

  // TTC
  const montantImmediatTTC = indemn.montantIndemniteImmediateTTCAvant - valueOrZero(indemn.montantPenaliteImmediateTTC)
  const montantDiffereTTC = indemn.montantIndemniteDiffereeTTCAvant - valueOrZero(indemn.montantPenaliteDiffereeTTC)

  if (indemn.montantFranchiseTTC) {
    if (montantImmediatTTC + montantDiffereTTC < indemn.montantFranchiseTTC) {
      // La franchise est supèrieure à l'immédiat + le différé
      indemn.montantFranchiseImmediateTTC = montantImmediatTTC
      indemn.montantFranchiseDiffereeTTC = montantDiffereTTC
    } else {
      if (montantImmediatTTC > indemn.montantFranchiseTTC) {
        // La franchise est inférieure à l'immédiat
        indemn.montantFranchiseImmediateTTC = indemn.montantFranchiseTTC
        indemn.montantFranchiseDiffereeTTC = 0
      } else {
        // La franchise est supérieure à l'immédiat
        indemn.montantFranchiseImmediateTTC = montantImmediatTTC
        const FranchiseInitiale = indemn.montantFranchiseTTC - montantDiffereTTC

        if (montantDiffereTTC > FranchiseInitiale) {
          // La franchise est inférieure au différé
          indemn.montantFranchiseDiffereeTTC = FranchiseInitiale
        } else {
          indemn.montantFranchiseDiffereeTTC = 0
        }
      }
    }
  }

  // Calcul de l'indemnité après pénalité et franchise
  // Immédiat
  if (indemn.montantFranchiseImmediateTTC) {
    indemn.montantIndemniteImmediateTTCApres = montantImmediatTTC - indemn.montantFranchiseImmediateTTC
  } else {
    indemn.montantIndemniteImmediateTTCApres = montantImmediatTTC
  }
  // Différé
  if (indemn.montantFranchiseDiffereeTTC) {
    indemn.montantIndemniteDiffereeTTCApres = montantDiffereTTC - indemn.montantFranchiseDiffereeTTC
  } else {
    indemn.montantIndemniteDiffereeTTCApres = montantDiffereTTC
  }

  return indemn
}

/* calculIndemnisationSinapps declaration ----------------------------------- */
export const calculIndemnisationSinapps = (dommages: DommageSinapps[], biens: DommageBienSinapps[], existingIndemnisationsSinapps: IndemnisationSinappsExistante[]): IndemnisationSinapps[] => {
  const indemnisationsSinapps: IndemnisationSinapps[] = []

  const isValidNumber = (value?: number | string) => {
    return !(value === undefined || value === '' || value === null)
  }

  function createIndemnisationLine(idGarantie: string) {
    indemnisationsSinapps.push({
      idGarantie: idGarantie,
      montantIndemniteImmediateHTAvant: 0,
      montantIndemniteImmediateTTCAvant: 0,
      montantIndemniteImmediateHTApres: 0,
      montantIndemniteImmediateTTCApres: 0,
      montantIndemniteDiffereeHTAvant: 0,
      montantIndemniteDiffereeTTCAvant: 0,
      montantIndemniteDiffereeHTApres: 0,
      montantIndemniteDiffereeTTCApres: 0,
      tauxPenalite: undefined,
      montantPenaliteImmediateHT: 0,
      montantPenaliteImmediateTTC: 0,
      montantPenaliteDiffereeHT: 0,
      montantPenaliteDiffereeTTC: 0,
      montantFranchiseImmediateHT: undefined,
      montantFranchiseImmediateTTC: 0,
      montantFranchiseDiffereeHT: 0,
      montantFranchiseDiffereeTTC: 0,
      montantFranchiseTTC: undefined,
      montantFranchiseHT: undefined,
    })
  }

  dommages.forEach((dmg) => {
    const idGarantie = dmg.idGarantie

    if (idGarantie && idGarantie !== '') {
      const indemn = indemnisationsSinapps.find((indem) => indem.idGarantie === idGarantie)

      if (!indemn) {
        createIndemnisationLine(idGarantie)
      }

      const index = indemnisationsSinapps.findIndex((indem) => indem.idGarantie === idGarantie)
      const montantVetusteAUtiliserHT = dmg.montantVetusteHT > dmg.montantVetusteRecuperableHT ? dmg.montantVetusteRecuperableTTC : dmg.montantVetusteHT

      // Calcul HT
      if (isValidNumber(dmg.plafond) && dmg.plafond !== undefined && dmg.plafond >= 0 && dmg.plafond < dmg.montantVetusteDeduiteHT + montantVetusteAUtiliserHT) {
        if (dmg.plafond <= dmg.montantVetusteDeduiteHT) {
          indemnisationsSinapps[index].montantIndemniteImmediateHTAvant += dmg.plafond
          indemnisationsSinapps[index].montantIndemniteDiffereeHTAvant += 0
        } else {
          indemnisationsSinapps[index].montantIndemniteImmediateHTAvant += dmg.montantVetusteDeduiteHT
          indemnisationsSinapps[index].montantIndemniteDiffereeHTAvant += dmg.plafond - dmg.montantVetusteDeduiteHT
        }
      } else {
        indemnisationsSinapps[index].montantIndemniteImmediateHTAvant += dmg.montantVetusteDeduiteHT
        indemnisationsSinapps[index].montantIndemniteDiffereeHTAvant += montantVetusteAUtiliserHT
      }

      // Calcul TTC
      const montantVetusteAUtiliserTTC = dmg.montantVetusteTTC > dmg.montantVetusteRecuperableTTC ? dmg.montantVetusteRecuperableTTC : dmg.montantVetusteTTC
      if (isValidNumber(dmg.plafond) && dmg.plafond !== undefined && dmg.plafond >= 0 && dmg.plafond < dmg.montantVetusteDeduiteTTC + montantVetusteAUtiliserTTC) {
        if (dmg.plafond <= dmg.montantVetusteDeduiteTTC) {
          indemnisationsSinapps[index].montantIndemniteImmediateTTCAvant += dmg.plafond
          indemnisationsSinapps[index].montantIndemniteDiffereeTTCAvant += 0
        } else {
          indemnisationsSinapps[index].montantIndemniteImmediateTTCAvant += dmg.montantVetusteDeduiteTTC
          indemnisationsSinapps[index].montantIndemniteDiffereeTTCAvant += dmg.plafond - dmg.montantVetusteDeduiteTTC
        }
      } else {
        indemnisationsSinapps[index].montantIndemniteImmediateTTCAvant += dmg.montantVetusteDeduiteTTC
        indemnisationsSinapps[index].montantIndemniteDiffereeTTCAvant += montantVetusteAUtiliserTTC
      }
    }
  })

  biens.forEach((bien) => {
    const idGarantie = bien.idGarantie
    if (idGarantie && idGarantie !== '') {
      const indemn = indemnisationsSinapps.find((indem) => indem.idGarantie === idGarantie)

      if (!indemn) {
        createIndemnisationLine(idGarantie)
      }

      const index = indemnisationsSinapps.findIndex((indem) => indem.idGarantie === idGarantie)

      // Calculs HT
      const montantVetusteAUtiliserHT = bien.montantVetusteHT > bien.montantVetusteRecuperableHT ? bien.montantVetusteRecuperableHT : bien.montantVetusteHT
      if (isValidNumber(bien.plafond) && bien.plafond !== undefined && bien.plafond >= 0 && bien.plafond < bien.montantHT) {
        if (bien.plafond <= bien.montantHT - montantVetusteAUtiliserHT) {
          indemnisationsSinapps[index].montantIndemniteImmediateHTAvant += bien.plafond
          indemnisationsSinapps[index].montantIndemniteDiffereeHTAvant += 0
        } else {
          indemnisationsSinapps[index].montantIndemniteImmediateHTAvant += bien.montantHT - bien.montantVetusteHT
          indemnisationsSinapps[index].montantIndemniteDiffereeHTAvant += bien.plafond - (bien.montantHT - montantVetusteAUtiliserHT)
        }
      } else {
        indemnisationsSinapps[index].montantIndemniteImmediateHTAvant += bien.montantHT - bien.montantVetusteHT
        indemnisationsSinapps[index].montantIndemniteDiffereeHTAvant += montantVetusteAUtiliserHT
      }

      // Calculs TTC
      const montantVetusteAUtiliserTTC = bien.montantVetusteTTC > bien.montantVetusteRecuperableTTC ? bien.montantVetusteRecuperableTTC : bien.montantVetusteTTC
      if (isValidNumber(bien.plafond) && bien.plafond !== undefined && bien.plafond >= 0 && bien.plafond < bien.montantTTC) {
        if (bien.plafond <= bien.montantTTC - montantVetusteAUtiliserTTC) {
          indemnisationsSinapps[index].montantIndemniteImmediateTTCAvant += bien.plafond
          indemnisationsSinapps[index].montantIndemniteDiffereeTTCAvant += 0
        } else {
          indemnisationsSinapps[index].montantIndemniteImmediateTTCAvant += bien.montantTTC - bien.montantVetusteTTC
          indemnisationsSinapps[index].montantIndemniteDiffereeTTCAvant += bien.plafond - (bien.montantTTC - montantVetusteAUtiliserTTC)
        }
      } else {
        indemnisationsSinapps[index].montantIndemniteImmediateTTCAvant += bien.montantTTC - bien.montantVetusteTTC
        indemnisationsSinapps[index].montantIndemniteDiffereeTTCAvant += montantVetusteAUtiliserTTC
      }
    }
  })

  return indemnisationsSinapps.map((indemn) => roundValues(calculIndemniteSinapps(indemn, existingIndemnisationsSinapps)))
}
