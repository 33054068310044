/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom'
import {
  useGetCaseInfosQuery,
  useGetNatureDetailBienListQuery,
} from 'store/api'
import { isValidString } from 'helpers/isValidString'
import { calculateAllCompensations } from 'services/CompensationService'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  CardContent,
} from '@mui/material'
import { AddRounded } from '@mui/icons-material'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import TravelerLargeTitle from 'components/TravelerLargeTitle/TravelerLargeTitle'
import Tabs from 'components/Tabs/Tabs'
import TabPanel from 'components/TabPanel/TabPanel'
import DamageLine from './DamageLine/DamageLine'

/* Type imports ------------------------------------------------------------- */
import type {
  ActeurTraveller,
  Dommage,
  IndemnisationTraveller,
} from 'API/__generated__/Api'
import { TypeDeBien } from 'API/__generated__/Api'
import type { TravelerForm } from 'types/TravelerForm'

/* Styled components -------------------------------------------------------- */
interface TableRowProps {
  border?: boolean;
}

const TableRow = styled.div<TableRowProps>`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 12% 13% 10% 8% 12% 14% 10% 65px;
  gap: 2%;
  justify-content: stretch;
  align-items: flex-start;
  border-bottom: ${(props) => props.border ? `1px solid ${props.theme.colors.grey}` : 'none'};
`

const CardContentContainer = styled(CardContent)`
  padding-left: 20px;
  @media screen and (max-width: 768px) {
    padding-left: 10px;
    padding-right: 0px;
    overflow-x: overlay;
  }
`

const BoldUppercaseTitle = styled(FormBoldTitle)`
  text-transform: uppercase;
  font-size: .9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
  word-break: break-word;
`

const AddButton = styled(Button)`
  padding: 5px;
  min-width: fit-content;
  margin-right: 8px;
`

const AddIcon = styled(AddRounded)`
  font-size: 1.5rem;
`

const AddDamagesButton = styled(Button)`
  margin-left: auto;
  width: 220px;
`

const FormContainer = styled.div`
  padding-bottom: 40px;
`

const BoldSeparator = styled.div`
  width: calc(100% + 40px);
  margin-left: -20px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

/* Component declaration ---------------------------------------------------- */
interface DamagesPageProps {}

const DamagesPage: React.FC<DamagesPageProps> = () => {
  const formikForm = useOutletContext<TravelerForm>()
  const navigate = useNavigate()
  const { caseId = '' } = useParams<{ caseId: string }>()
  const [ tabValue, setTabValue ] = useState<number>(0)

  const {
    currentData: caseInfos,
    isFetching: isFetchingCaseInfos,
  } = useGetCaseInfosQuery(caseId)
  const {
    currentData: natureDetailBien = [],
    isFetching: isFetchingNatureDetailBien,
  } = useGetNatureDetailBienListQuery(caseId)

  if (formikForm.values.acteurs === undefined || formikForm.values.acteurs.length === 0) {
    return null
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number): void => {
    setTabValue(newValue)
  }

  const addDamage = (damageType: string): void => {
    navigate(`/dossiers/${caseId}/traveller/nouveau/dommage/${formikForm.values.acteurs[tabValue]?.id}/${damageType}`)
  }

  const editDamage = (damageType: string, damageId: string, roomIndex?: number): void => {
    navigate(`/dossiers/${caseId}/traveller/modification/dommage/${formikForm.values.acteurs[tabValue]?.id}/${damageType}/${damageId}${roomIndex !== undefined ? `/${roomIndex}`: ''}`)
  }

  const getDamagesByType = (person: ActeurTraveller, type: TypeDeBien.Mobilier | TypeDeBien.Autre): Dommage[] => {
    if (type === TypeDeBien.Mobilier) {
      return person.dommagesMobilierDivers?.filter((dommage) => dommage.typeBien === TypeDeBien.Mobilier) || []
    }
    if (type === TypeDeBien.Autre) {
      return person.dommagesMobilierDivers?.filter((dommage) => dommage.typeBien === TypeDeBien.Autre) || []
    }
    return []
  }

  const calculateNewCompensation = (damages: Dommage[], acteurIndex: number, oldCompensation?: IndemnisationTraveller) => {
    formikForm.setFieldValue(`acteurs[${acteurIndex}].indemnisation.indemnisations`, calculateAllCompensations({ damages, natureDetailBien, isSinapps: caseInfos?.mission.origine?.code === 'SIN', oldCompensation }))
  }

  const handleDeleteImmobilierDamage = (person: ActeurTraveller, roomIndex: number, damageIndex: number) => {
    const rooms = structuredClone(person.pieces) || []
    const acteurIndex = formikForm.values.acteurs.findIndex((acteur) => acteur.id === person.id)

    rooms[roomIndex].dommagesImmobilierEmbellissement?.splice(damageIndex, 1)
    formikForm.setFieldValue(`acteurs[${acteurIndex}].pieces`, rooms)
    calculateNewCompensation(
      [
        ...rooms.flatMap((room) => room.dommagesImmobilierEmbellissement) || [],
        ...person.dommagesMobilierDivers || [],
      ],
      acteurIndex,
      person.indemnisation,
    )
  }

  const handleDeleteDamage = (person: ActeurTraveller, damageType: TypeDeBien.Autre | TypeDeBien.Mobilier, damageIndex: number) => {
    const cleanedDamage = getDamagesByType(person, damageType)
    cleanedDamage.splice(damageIndex, 1)
    const dommagesMobilierDivers = [
      ...cleanedDamage,
      ...getDamagesByType(person, damageType === TypeDeBien.Autre ? TypeDeBien.Mobilier : TypeDeBien.Autre),
    ]
    const acteurIndex = formikForm.values.acteurs.findIndex((acteur) => acteur.id === person.id)

    formikForm.setFieldValue(`acteurs[${acteurIndex}].dommagesMobilierDivers`, dommagesMobilierDivers)
    calculateNewCompensation(
      [
        ...person.pieces?.flatMap((room) => room.dommagesImmobilierEmbellissement) || [],
        ...dommagesMobilierDivers,
      ],
      acteurIndex,
      person.indemnisation,
    )
  }

  const getTabs = (): string [] => {
    return [ ...formikForm.values.acteurs.filter((person) => !person.isDeleted).map((person, index: number) => person.nom ?? `Personne ${index + 1}`) ]
  }

  return (
    <FormContainer>
      <div>
        <TravelerLargeTitle>
          Dommages
          <AddDamagesButton
            variant="contained"
            onClick={() => addDamage(TypeDeBien.Immobilier)}
            disabled={!isValidString(formikForm.values.acteurs[tabValue]?.id) || formikForm.values.disabled}
          >
            Ajouter des dommages
          </AddDamagesButton>
        </TravelerLargeTitle>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          tabs={getTabs()}
        />
        {
          formikForm.values.acteurs?.filter((person) => !person.isDeleted).map((person, index) => (
            <TabPanel
              value={tabValue}
              index={index}
              key={`${person.id}-${index}`}
            >
              <Card>
                <CardContentContainer>
                  <BoldUppercaseTitle>
                    <TableRow>
                      <div>
                        Libellé
                      </div>
                      <div>
                        Type réparation
                      </div>
                      <div>
                        Montant HT
                      </div>
                      <div>
                        TVA
                      </div>
                      <div>
                        Montant TTC
                      </div>
                      <div>
                        Montant vétusté
                      </div>
                      <div>
                        VVD TTC
                      </div>
                      <div />
                      <div />
                    </TableRow>
                  </BoldUppercaseTitle>
                  <BoldSeparator />
                  <BoldUppercaseTitle>
                    Immobilier / Embellissement
                    <AddButton
                      variant="contained"
                      onClick={() => addDamage(TypeDeBien.Immobilier)}
                      disabled={!isValidString(formikForm.values.acteurs[tabValue]?.id) || formikForm.values.disabled}
                    >
                      <AddIcon />
                    </AddButton>
                  </BoldUppercaseTitle>
                  {
                    person.pieces?.map((room, roomIndex) => (
                      <div key={`${room.compteur}-${index}-${roomIndex}`}>
                        <FormBoldTitle smaller>
                          {room.libelle}
                        </FormBoldTitle>
                        {
                          room.dommagesImmobilierEmbellissement.map((dommage, dommageIndex) => (
                            <DamageLine
                              key={`${dommage.id}-${index}-${dommageIndex}`}
                              damage={dommage}
                              border={dommageIndex !== room.dommagesImmobilierEmbellissement.length -1}
                              disabled={formikForm.values.disabled}
                              onDelete={() => handleDeleteImmobilierDamage(person, roomIndex, dommageIndex)}
                              onEdit={() => editDamage(dommage.typeBien ?? TypeDeBien.Immobilier, dommage.id, room.compteur)}
                            />
                          ))
                        }
                      </div>
                    ))
                  }
                  <BoldSeparator />
                  <BoldUppercaseTitle>
                    Mobilier
                    <AddButton
                      variant="contained"
                      onClick={() => addDamage(TypeDeBien.Mobilier)}
                      disabled={!isValidString(formikForm.values.acteurs[tabValue]?.id) || formikForm.values.disabled}
                    >
                      <AddIcon />
                    </AddButton>
                  </BoldUppercaseTitle>
                  {
                    getDamagesByType(person, TypeDeBien.Mobilier)
                      .map((dommage, dommageIndex) => (
                        <DamageLine
                          key={`${dommage.id}-${index}-${dommageIndex}`}
                          damage={dommage}
                          border={dommageIndex !== getDamagesByType(person, TypeDeBien.Mobilier).length -1}
                          disabled={formikForm.values.disabled}
                          onDelete={() => handleDeleteDamage(person, TypeDeBien.Mobilier, dommageIndex)}
                          onEdit={() => editDamage(TypeDeBien.Mobilier, dommage.id)}
                        />
                      ))
                  }
                  <BoldSeparator />
                  <BoldUppercaseTitle>
                    Divers
                    <AddButton
                      variant="contained"
                      onClick={() => addDamage(TypeDeBien.Autre)}
                      disabled={!isValidString(formikForm.values.acteurs[tabValue]?.id) || formikForm.values.disabled}
                    >
                      <AddIcon />
                    </AddButton>
                  </BoldUppercaseTitle>
                  {
                    getDamagesByType(person, TypeDeBien.Autre)
                      .map((dommage: Dommage, dommageIndex) => (
                        <DamageLine
                          key={`${dommage.id}-${index}-${dommageIndex}`}
                          damage={dommage}
                          border={dommageIndex !== getDamagesByType(person, TypeDeBien.Autre).length -1}
                          disabled={formikForm.values.disabled || isFetchingNatureDetailBien || isFetchingCaseInfos}
                          onDelete={() => handleDeleteDamage(person, TypeDeBien.Autre, dommageIndex)}
                          onEdit={() => editDamage(TypeDeBien.Autre, dommage.id)}
                        />
                      ))
                  }
                </CardContentContainer>
              </Card>
            </TabPanel>
          ))
        }
      </div>
    </FormContainer>
  )
}

export default DamagesPage
