/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import { useDispatch } from 'react-redux'
import { open as openSidebar } from 'store/slices/caseSidebarSlice'

/* Component imports -------------------------------------------------------- */
import InfoRounded from '@mui/icons-material/InfoRounded'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'

/* Type imports ------------------------------------------------------------- */
import type { ButtonProps } from '@mui/material'

/* Component declaration ---------------------------------------------------- */
interface OpenSidebarButtonProps extends ButtonProps {
  caseId: string;
  disabled?: boolean;
}

const OpenSidebarButton: React.FC<OpenSidebarButtonProps> = ({ caseId, disabled = false, ...rest }) => {
  const dispatch = useDispatch()

  const openSidebarWithCaseId = (): void => {
    dispatch(
      openSidebar({
        open: true,
        caseId,
      }),
    )
  }

  return (
    <CustomIconButton
      Icon={InfoRounded}
      onClick={(e) => {e.stopPropagation(); openSidebarWithCaseId()}}
      onTouchStart={(e) => {e.stopPropagation(); openSidebarWithCaseId()}}
      disabled={disabled}
      {...rest}
    />
  )
}

export default OpenSidebarButton
