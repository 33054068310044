/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { handleNumberVerificationAllowEmpty } from 'helpers/numberUtils'
import {
  useGetJustificationListQuery,
  useGetReparabiliteListQuery,
  useGetSinappsBienTypeListQuery,
} from 'store/api'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import PriceField from 'components/FieldWithInputAdornment/PriceField'
import PriceDiv from 'components/FieldWithInputAdornment/PriceDiv'
import CompensationTableRow from './CompensationTableComponents/CompensationTableRow'
import CompensationTableBoldUppercaseTitle from './CompensationTableComponents/CompensationTableBoldUppercaseTilte'
import CompensationTableBoldSeparator from './CompensationTableComponents/CompensationTableBoldSeparator'
import CompensationPersonTableCard from './CompensationTableComponents/CompensationPersonTableCard'

/* Type imports ------------------------------------------------------------- */
import type {
  Dommage,
  DommageBienSinapps,
  Justifications,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const TableDommageBienSinappsRow = styled(CompensationTableRow)`
  grid-template-columns: repeat(5, 10%) repeat(2, 12%) 12%;
  gap: 2%;
`

const Title = styled(FormBoldTitle)`
  margin-top: 10px;
  margin-bottom: -20px;
`

/* Component declaration ---------------------------------------------------- */
interface CompensationPersonSinappsPropertyDamageTableProps {
  personIndexName: string;
  dommagesBienSinapps: DommageBienSinapps[];
  isHT: boolean;
  disabled: boolean;
  handleValue: (type: string, value: number | '') => void;
}

const CompensationPersonSinappsPropertyDamageTable: React.FC<CompensationPersonSinappsPropertyDamageTableProps> = ({
  personIndexName,
  dommagesBienSinapps,
  isHT,
  disabled,
  handleValue,
}) => {
  const {
    currentData: reparabiliteList = [],
    isFetching: isFetchingReparabiliteList,
  } = useGetReparabiliteListQuery()
  const {
    currentData: justificationList = [],
    isFetching: isFetchingJustificationList,
  } = useGetJustificationListQuery()
  const {
    currentData: bienTypeList = [],
    isFetching: isFetchingBienTypeList,
  } = useGetSinappsBienTypeListQuery()

  const getLibelle = (line: Dommage) => {
    const detail = bienTypeList.find((bien) => bien.code === line.sinapps?.natureDuBienSinapps?.code)?.libelle ?? line.typeBien

    const capitalizeFirstLetter = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

    return `${capitalizeFirstLetter(detail || '')}`
  }

  return (
    <>
      <FormBoldTitle bigger>
        Récapitulatif des domages bien Sinapps
      </FormBoldTitle>
      {(isFetchingReparabiliteList || isFetchingJustificationList || isFetchingBienTypeList) && <CircularProgress />}
      <CompensationPersonTableCard>
        <CompensationTableBoldUppercaseTitle>
          <TableDommageBienSinappsRow>
            <div>
              Descriptif
            </div>
            <div>
              Valeur à neuf / Estimation
            </div>
            <div>
              Date d'achat
            </div>
            <div>
              Montant vetusté
            </div>
            <div>
              Plafond vétusté rec.
            </div>
            <div>
              Réparabilité
            </div>
            <div>
              Justification
            </div>
            <div>
              Plafond
            </div>
          </TableDommageBienSinappsRow>
        </CompensationTableBoldUppercaseTitle>
        <CompensationTableBoldSeparator />
        {
          dommagesBienSinapps.map((dommage, index) => (
            <div key={`${dommage.id}-${index}`}>
              <Title>
                {getLibelle(dommage as Dommage)}
              </Title>
              <TableDommageBienSinappsRow border={index !== (dommagesBienSinapps?.length || 0) -1}>
                <div>
                  {dommage.descriptif}
                </div>
                <PriceDiv price={isHT ? dommage.montantHT : dommage.montantTTC} />
                <div>
                  {dommage.dateAchat}
                </div>
                <PriceDiv price={isHT ? dommage.montantVetusteHT : dommage.montantVetusteTTC} />
                <PriceDiv price={isHT ? dommage.montantVetusteRecuperableHT : dommage.montantVetusteRecuperableTTC} />
                <div>
                  {reparabiliteList.find((value) => value.code === dommage.reparation)?.libelle ?? dommage.reparation}
                </div>
                <div>
                  {justificationList.find((value) => value.code as Justifications === dommage.justification?.code)?.libelle ?? dommage.justification?.libelle ?? dommage.justification?.code}
                </div>
                <PriceField
                  placeholder="Plafond"
                  size="small"
                  value={dommage.plafond || ''}
                  name={`${personIndexName}.dommagesBienSinapps.[${index}].plafond`}
                  onChange={(e) => handleValue(`${personIndexName}.dommagesBienSinapps.[${index}].plafond`, handleNumberVerificationAllowEmpty(e.target.value, 2))}
                  disabled={disabled}
                />
              </TableDommageBienSinappsRow>
            </div>
          ))
        }
        <CompensationTableBoldSeparator />
        <FormBoldTitle smaller>
          <TableDommageBienSinappsRow>
            Totaux
            <PriceDiv price={dommagesBienSinapps.reduce((a, b) => a + Number(isHT ? b.montantHT : b.montantTTC), 0)} />
            <div />
            <PriceDiv price={dommagesBienSinapps.reduce((a, b) => a + Number(isHT ? b.montantVetusteHT : b.montantVetusteTTC), 0)} />
            <PriceDiv price={dommagesBienSinapps.reduce((a, b) => a + Number(isHT ? b.montantVetusteRecuperableHT : b.montantVetusteRecuperableTTC), 0)} />
            <div />
            <div />
            <PriceDiv price={dommagesBienSinapps.reduce((a, b) => a + Number(b.plafond || 0), 0)} />
          </TableDommageBienSinappsRow>
        </FormBoldTitle>
      </CompensationPersonTableCard>
    </>
  )
}

export default CompensationPersonSinappsPropertyDamageTable
