/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Component imports -------------------------------------------------------- */
import { InputAdornment } from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'

/* Type imports ------------------------------------------------------------- */
import type { FieldAttributes } from 'formik'

/* Component declaration ---------------------------------------------------- */
interface RateFieldProps {
  placeholder: string;
  name: string;
  size?: string;
}

const RateField: React.FC<FieldAttributes<RateFieldProps>> = ({
  name,
  placeholder,
  ...props
}) => {
  return (
    <Field
      component={TextField}
      name={name}
      type="number"
      placeholder={placeholder}
      InputProps={
        {
          endAdornment: (
            <InputAdornment position="end">
              %
            </InputAdornment>
          ),
          inputProps: {
            style: { textAlign: 'right' },
            min: 0,
            max: 100,
          },
          onWheel: (e: React.ChangeEvent<HTMLInputElement>) => e.target.blur(),
        }
      }
      {...props}
    />
  )
}

export default RateField
