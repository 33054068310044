/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useGetSinappsSolicitationListQuery } from 'store/api'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  TextField as MuiTextField,
} from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import Loader from 'components/Loader/Loader'

/* Type imports ------------------------------------------------------------- */
import type {
  CommandeCloturerSollicitationRequest,
  Sollicitation,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const ClosedFormControlLabel = styled(FormControlLabel)`
  width: 100%;
  height: 40px;
  margin: 0;
  color: ${(props) => props.theme.palette.secondary.main};
`

const ClosedCheckbox = styled(Checkbox)`
  padding-left: 0;
`

const SolicitationTitle = styled(FormBoldTitle)`
  margin-top: 10px;
  text-transform: uppercase;
`

const CardContentContainer = styled(CardContent)`
  overflow-x: overlay;
`

const SolicitationTitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
`

const InfoGrid = styled.div`
  display: grid;

  grid-template-columns: .5fr .6fr 1fr 0.8fr 1fr;
  gap: 10px;

  align-items: stretch;
  justify-content: stretch;
  margin-top: 10px;
  padding-bottom: 20px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
  font-size: 15px;
`

const BoldText = styled.div`
  font-weight: bold;
`

const BorderedDiv = styled.div`
  padding-right: 10px;
  border-right: 2px solid ${(props) => props.theme.colors.grey};
`

interface SolicitationCardProps {
  checked: boolean;
}

const SolicitationCard = styled(Card)<SolicitationCardProps>`
  margin-bottom: 40px;
  border: ${(props) => props.checked ? `2px solid ${props.theme.palette.primary.main} !important` : 'inherit'};
`

/* Component declaration ---------------------------------------------------- */
interface QaSinappsCloseSolicitationProps {
  handleChange: (value: CommandeCloturerSollicitationRequest[]) => void;
  values: CommandeCloturerSollicitationRequest[];
  caseId: string;
}

const QaSinappsCloseSolicitation: React.FC<QaSinappsCloseSolicitationProps> = ({
  values,
  handleChange,
  caseId,
}) => {
  const {
    currentData: solicitationList = [],
    isFetching: isFetchingSolicitations,
  } = useGetSinappsSolicitationListQuery(caseId)
  // DOSSIER
  // 2021-XX-00000140

  const handleChecked = (e: React.SyntheticEvent, solicitation: Sollicitation): void => {
    const checked = (e.target as HTMLInputElement).checked
    let oldValues: CommandeCloturerSollicitationRequest[] = [ ...values ]

    if (checked) {
      oldValues.push({ guidSollicitation: solicitation.guid, commentaire: solicitation.commentaireCloture || '' })
    } else {
      oldValues = oldValues.filter((val) => val.guidSollicitation !== solicitation.guid)
    }

    handleChange(oldValues)
  }

  const isChecked = (solicitation: Sollicitation): boolean => values.some((val) => val.guidSollicitation === solicitation.guid)

  const solicitations = useMemo(() => [ ...solicitationList ].sort((a, b) => (a.cloturee === b.cloturee)? 0 : a.cloturee ? 1 : -1),
    [
      solicitationList,
    ])

  return (
    <>
      {isFetchingSolicitations && <Loader />}
      {
        solicitations.map((solicitation, index) => (
          <SolicitationCard
            key={`${solicitation.guid}-${index}`}
            checked={isChecked(solicitation)}
          >
            <CardContentContainer>
              <SolicitationTitleContainer>
                <div>
                  {
                    solicitation.cloturee === false ?
                      <ClosedFormControlLabel
                        checked={isChecked(solicitation)}
                        control={<ClosedCheckbox />}
                        onChange={(e): void => handleChecked(e, solicitation)}
                        label={
                          <SolicitationTitle>
                            Sollicitation N°
                            {solicitation.guid}
                          </SolicitationTitle>
                        }
                      /> :
                      <SolicitationTitle>
                        Sollicitation N°
                        {solicitation.guid}
                      </SolicitationTitle>
                  }
                </div>
                <ColoredSquareChip color={solicitation.cloturee === false ? 'green' : 'orange'}>
                  {
                    solicitation.cloturee === false ?
                      'Sollicitation non clôturée':
                      'Sollicitation clôturée'
                  }
                </ColoredSquareChip>
              </SolicitationTitleContainer>
              <InfoGrid>
                <BorderedDiv>
                  Emetteur :
                  <BoldText>
                    {solicitation.emetteur}
                  </BoldText>
                </BorderedDiv>
                <BorderedDiv>
                  Destinataire :
                  <BoldText>
                    {solicitation.destinataire}
                  </BoldText>
                </BorderedDiv>
                <BorderedDiv>
                  Type de sollicitation :
                  <BoldText>
                    {solicitation.typeDeSollicitation}
                  </BoldText>
                </BorderedDiv>
                <BorderedDiv>
                  Type de l'intervention :
                  <BoldText>
                    {solicitation.typeIntervention}
                  </BoldText>
                </BorderedDiv>
                <div>
                  Commentaire demande :
                  <BoldText>
                    {solicitation.commentaireDemande}
                  </BoldText>
                </div>
              </InfoGrid>
              <FormBoldTitle>
                Commentaire
              </FormBoldTitle>
              {
                solicitation.cloturee === false && isChecked(solicitation) ?
                  <Field
                    component={TextField}
                    name={`closeSolicitation[${values.findIndex((val) => val.guidSollicitation === solicitation.guid)}].commentaire`}
                    placeholder="Votre message"
                    rows={3}
                    multiline
                  /> :
                  <MuiTextField
                    value={solicitation.commentaireCloture || ''}
                    multiline
                    disabled
                  />
              }
            </CardContentContainer>
          </SolicitationCard>
        ))
      }
    </>
  )
}

export default QaSinappsCloseSolicitation
