/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useRef,
  useState,
} from 'react'

/* Module imports ----------------------------------------------------------- */
import {
  Outlet,
  useParams,
  useLocation,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetCaseInfosQuery,
  useGetCaseReportsQuery,
  useGetCaseTravelerQuery,
  useGetNatureDetailBienListQuery,
  useGetTravelerIrsiQuery,
} from 'store/api'
import {
  useAppDispatch,
  useSelectedReport,
  useReports,
} from 'store/hooks'
import { setCaseReports } from 'store/slices/reportSlice'
import TravelerService from 'services/TravelerService'
import { calculateAllCompensations } from 'services/CompensationService'

/* Component imports -------------------------------------------------------- */
import Loader from 'components/Loader/Loader'

/* Type imports ------------------------------------------------------------- */
import type { FormikHelpers } from 'formik'
import { EtatRapport } from 'API/__generated__/Api'
import type {
  TravelerAndUtils,
  TravelerForm,
} from 'types/TravelerForm'
import TravelerFormInitialValues from 'types/TravelerFormInitialValues'
import { travelerSchema } from 'types/TravelerForm'

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutProps {}

const CaseLayout: React.FC<CaseLayoutProps> = () => {
  const formRef = useRef<HTMLFormElement>()
  const { caseId = '' } = useParams<{ caseId: string }>()
  const selectedReport = useSelectedReport(caseId)
  const reports = useReports(caseId)
  const location = useLocation()
  const dispatch = useAppDispatch()
  const [ firstTravelerSubmit, setFirstTravelerSubmit ] = useState<boolean>(false)

  const {
    currentData: caseInfos,
    isFetching: isFetchingCaseInfos,
  } = useGetCaseInfosQuery(caseId)
  const {
    currentData: reportList = [],
    isFetching: isFetchingReportList,
  } = useGetCaseReportsQuery(caseId)
  const {
    currentData: natureDetailBien = [],
    isFetching: isFetchingNatureDetailBien,
  } = useGetNatureDetailBienListQuery(caseId)
  const {
    currentData: travelerData,
    isFetching: isFetchingTravelerData,
  } = useGetCaseTravelerQuery(selectedReport || '', { skip: !selectedReport })
  const {
    currentData: irsiData,
    isFetching: isFetchingIrsiData,
  } = useGetTravelerIrsiQuery(caseId)

  const onSubmit = (values: TravelerAndUtils, { setSubmitting }: FormikHelpers<TravelerAndUtils>): void => {
    console.log('CaseLayout onSubmit values', values)
    setSubmitting(false)
  }

  const formikForm: TravelerForm = useForm<TravelerAndUtils>(
    {
      initialValues: TravelerFormInitialValues as TravelerAndUtils,
      onSubmit: onSubmit,
      validationSchema: travelerSchema,
    },
  )

  const errorForm: TravelerForm = useForm<TravelerAndUtils>(
    {
      initialValues: TravelerFormInitialValues as TravelerAndUtils,
      onSubmit: onSubmit,
      validationSchema: travelerSchema,
    },
  )

  useEffect(() => {
    if (!isFetchingReportList) {
      dispatch(setCaseReports({ caseId, reports: reportList }))
    }
  }, [ isFetchingReportList ])

  useEffect(() => {
    if (!isFetchingTravelerData && travelerData !== undefined && !isFetchingNatureDetailBien && !isFetchingCaseInfos) {
      const data = structuredClone(travelerData)

      data.acteurs = data.acteurs.map((person) => {
        return ({
          ...person,
          indemnisation: calculateAllCompensations({
            damages: [
              ...person.pieces?.flatMap((room) => room.dommagesImmobilierEmbellissement) || [],
              ...person.dommagesMobilierDivers || [],
            ],
            natureDetailBien,
            isSinapps: caseInfos?.mission.origine?.code === 'SIN',
            oldCompensation: person.indemnisation,
          }),
        })
      })

      formikForm.setValues({
        ...formikForm.values,
        ...data,
      })
      formikForm.setTouched({})
    }
  }, [ isFetchingTravelerData, travelerData, isFetchingNatureDetailBien, isFetchingCaseInfos ])

  useEffect(() => {
    if (!isFetchingIrsiData && irsiData !== undefined) {
      formikForm.setFieldValue('irsiData', irsiData)
    }
  }, [ isFetchingIrsiData, irsiData ])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (reports.find((report) => report.id === selectedReport)?.etat.code === EtatRapport.Cloture || !caseId || !selectedReport) {
        return
      }
      if (firstTravelerSubmit && location.pathname.indexOf('/nouveau') === -1 && location.pathname.indexOf('/modification') === -1) {
        TravelerService.submitTraveler({
          formikForm,
          caseId,
          reportId: selectedReport,
          validation: false,
        })
      } else if (!firstTravelerSubmit) {
        setFirstTravelerSubmit(true)
      }
    }, 3000)

    return () => clearTimeout(timer)
  }, [
    formikForm.values.acteurs,
    formikForm.values.confidentiel,
    formikForm.values.conformiteDuRisque,
    formikForm.values.convention,
    formikForm.values.garanties,
    formikForm.values.infosDossier,
    formikForm.values.rendezVous,
    formikForm.values.sinistre,
    location.pathname,
  ])

  useEffect(() => {
    if (reports.find((report) => report.id === selectedReport)?.etat.code === EtatRapport.Cloture) {
      formikForm.setFieldValue('disabled', true)
    } else {
      formikForm.setFieldValue('disabled', false)
    }
  }, [ selectedReport ])

  return (
    <Form
      form={formikForm}
      innerRef={formRef}
      style={{ display: 'contents' }}
    >
      {(isFetchingTravelerData || isFetchingIrsiData) && <Loader />}
      <Outlet context={{ formikForm, errorForm }} />
    </Form>
  )
}

export default CaseLayout
