/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import CenteredPageContainer from 'layouts/PageContainer/CenteredPageContainer'
import MediaProcessorFallback from 'components/MediaProcessors/MediaProcessorFallback/MediaProcessorFallback'

/* Type imports ------------------------------------------------------------- */
import type { PieceJointe } from 'API/__generated__/Api'
import type { FileType } from 'helpers/getMediaFileType'

/* Lazy component imports --------------------------------------------------- */
const PdfProcessor = React.lazy(() => import(/* webpackPrefetch: true */ 'components/MediaProcessors/PdfProcessor/PdfProcessor' /* webpackChunkName: "pdf" */))

/* Styled components -------------------------------------------------------- */
const MediaLibraryContainer = styled.div`
  height: 100%;
`

interface ImageProps {
  sheetHeight: number;
}

const Image = styled.img<ImageProps>`
  height: calc(${(props) => props.sheetHeight}px - 18vh);
`

const ImageContainer = styled.div`
  width: 100%;
  text-align: center;
`

const ViewerPageContainer = styled(CenteredPageContainer)`
  padding: 0 !important;
  background-color: ${(props) => props.theme.palette.background.paper};
  color: ${(props) => props.theme.palette.primary.main};
  height: 100%;
  overflow: hidden;
`

/* Component declaration ---------------------------------------------------- */
interface MediaLibraryDataProps {
  displayType: FileType;
  sheetHeight: number;
  document: PieceJointe;
}

const MediaLibraryData: React.FC<MediaLibraryDataProps> = ({
  displayType,
  sheetHeight,
  document,
}) => {

  return (
    <MediaLibraryContainer>
      {
        displayType === 'img' ?
          <ImageContainer>
            <Image
              src={document?.url || ''}
              alt={document?.fileName}
              sheetHeight={sheetHeight}
            />
          </ImageContainer> :
          <ViewerPageContainer>
            {
              displayType === 'pdf' &&
                <MediaProcessorFallback type="pdf">
                  <PdfProcessor
                    isReadOnly
                    documentPath={document?.url || ''}
                  />
                </MediaProcessorFallback>
            }
          </ViewerPageContainer>
      }
    </MediaLibraryContainer>
  )
}

export default MediaLibraryData
