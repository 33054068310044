/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'store/hooks'
import {
  closeSWPopup,
  selectServiceWorker,
} from 'store/slices/serviceWorkerSlice'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material'

/* Type imports ------------------------------------------------------------- */
import type { ServiceWorkerState } from 'store/slices/serviceWorkerSlice'

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  text-align: center;
  margin-top: 10px;
`

const DialogContentContainer = styled(DialogContent)`
  align-self: center;
  margin-bottom: 5px;
`

/* Component declaration ---------------------------------------------------- */
interface ServiceWorkerUpdateModalProps {}

const ServiceWorkerUpdateModal: React.FC<ServiceWorkerUpdateModalProps> = () => {
  const serviceWorkerState: ServiceWorkerState = useAppSelector(selectServiceWorker)
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(closeSWPopup())
    window.location.reload()
  }

  return (
    <Dialog
      open={serviceWorkerState.openSWPopup}
      onClose={closeModal}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitleContainer>
        Une mise à jour est disponible, la page va être rechargée.
      </DialogTitleContainer>
      <DialogContentContainer>
        <Button
          onClick={closeModal}
          variant="contained"
        >
          OK
        </Button>
      </DialogContentContainer>
    </Dialog>
  )
}

export default ServiceWorkerUpdateModal
