/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Type imports ------------------------------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store/store'
import { HealthStatus } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
export interface NetworkState {
  hasConnection: boolean;
  APIHealthStatus: HealthStatus;
  fetchingRouteList: string[];
  offlineCases: string[];
}

/* Redux slice -------------------------------------------------------------- */
const networkSlice = createSlice(
  {
    name: 'network',
    initialState: {
      hasConnection: navigator.onLine,
      APIHealthStatus: HealthStatus.Healthy,
      fetchingRouteList: [],
      offlineCases: [],
    } as NetworkState,
    reducers: {
      setHasConnection: (state, { payload }: PayloadAction<boolean>) => {
        state.hasConnection = payload
      },
      setAPIHealthStatus: (state, { payload }: PayloadAction<HealthStatus>) => {
        state.APIHealthStatus = payload
      },
      addFetchingRoute: (state, { payload }: PayloadAction<string>) => {
        if (!state.fetchingRouteList) {
          state.fetchingRouteList = []
        }
        const index = state.fetchingRouteList.indexOf(payload)
        if (index === -1) {
          state.fetchingRouteList.push(payload)
        }
      },
      addOfflineCase: (state, { payload }: PayloadAction<string>) => {
        if (!state.offlineCases) {
          state.offlineCases = []
        }
        const index = state.offlineCases.indexOf(payload)
        if (index === -1) {
          state.offlineCases.push(payload)
        }
      },
      removeFetchingRoute: (state, { payload }: PayloadAction<string>) => {
        const index = state.fetchingRouteList.indexOf(payload)
        if (index !== -1) {
          state.fetchingRouteList.splice(index, 1)
        }
      },
    },
  },
)

/* Export slice components -------------------------------------------------- */
export const {
  setHasConnection,
  setAPIHealthStatus,
  addFetchingRoute,
  removeFetchingRoute,
  addOfflineCase,
} = networkSlice.actions

export default networkSlice.reducer

export const selectNetwork = (state: RootState): NetworkState => {
  return state.network
}
