/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { findCodeLabel } from 'helpers/findCodeLabel'
import { handleNumberVerification } from 'helpers/numberUtils'
import { isValidString } from 'helpers/isValidString'
import { enumToSegmentedButtonOptions } from 'helpers/enumToSegmentedButtonOptions'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  CardContent,
  MenuItem,
  Select,
} from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import CheckableButton from 'components/CheckableButton/CheckableButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import PriceField from 'components/FieldWithInputAdornment/PriceField'
import CompensationPersonCompensationTable from './CompensationPersonTables/CompensationPersonCompensationTable'
import CompensationPersonSinappsDamageTable from './CompensationPersonTables/CompensationPersonSinappsDamageTable'
import CompensationPersonSinappsPropertyDamageTable from './CompensationPersonTables/CompensationPersonSinappsPropertyDamageTable'
import CompensationPersonSinappsCompensationTable from './CompensationPersonTables/CompensationPersonSinappsCompensationTable'

/* Type imports ------------------------------------------------------------- */
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type {
  CodeLabel,
  Garantie,
  IndemnisationTraveller,
} from 'API/__generated__/Api'
import { RegimeFiscal } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface ErrorField {
  error?: boolean;
}

const TwoGridContainer = styled.div<ErrorField>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 10px;
  border: ${(props) => props.error ? '1px solid #d32f2f' : undefined};
  border-radius: 4px;
`

const GarantieGridContainer = styled.div`
  display: grid;
  grid-template-columns: 35% calc(65% - 20px);
  gap: 20px;
  align-items: flex-start;
`

const AddGarantieButton = styled(Button)`
  font-weight: bold;
`

const GarantieTitle = styled(FormBoldTitle)`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
`

const CardContainer = styled(Card)`
  margin-bottom: 20px;
  font-size: 14px;
`

const Container = styled.div`
  margin-top: -20px;
`

/* Component declaration ---------------------------------------------------- */
interface CompensationPersonProps {
  indemnisation: IndemnisationTraveller | undefined;
  garanties: Garantie[];
  handleValue: (type: string, value?: string | Garantie[] | CodeLabel | number, newGaranties?: Garantie[]) => void;
  personIndexName: string;
  compensationDamagePositionList: CodeLabel[];
  garantieList: CodeLabel[];
  sinappsGarantieList: CodeLabel[];
  disabled: boolean;
  isSinapps: boolean;
}

const CompensationPerson: React.FC<CompensationPersonProps> = ({
  indemnisation,
  garanties,
  handleValue,
  personIndexName,
  compensationDamagePositionList,
  garantieList,
  sinappsGarantieList,
  disabled,
  isSinapps,
}) => {
  const addGarantie = (): void => {
    const newGaranties = [
      ...garanties,
      {
        commentaire: '',
        acceptation: garantieList[0],
        montantDegatsNonCouverts: 0,
        code: sinappsGarantieList.find((list) => !garanties.some((gar) => gar.code?.code === list.code )) ?? { code: '', libelle: '' },
      },
    ]

    if (newGaranties.length === 1) {
      indemnisation?.indemnisations?.forEach((indemn, indexIndemn) => {
        if (!isValidString(indemn.garantie?.code)) {
          handleValue(
            `${personIndexName}.indemnisations.[${indexIndemn}].garantie`,
            newGaranties[0].code || undefined,
          )
        }
      })
    }

    handleValue('garanties', newGaranties)
  }

  const regimeOptions: SegmentedButtonOption<string>[] = enumToSegmentedButtonOptions(RegimeFiscal)
  const garantieOptions: SegmentedButtonOption<string>[] = garantieList.map((option) => ({ value: option.code, label: option.libelle }))

  const onChangeGarantieName = (value: string, index: number) => {
    const newGaranties = structuredClone(garanties)
    newGaranties[index].code = findCodeLabel(sinappsGarantieList, value)
    handleValue(`garanties.[${index}].code`, findCodeLabel(sinappsGarantieList, value), newGaranties)
  }

  return (
    <Container>
      {
        isSinapps &&
          <div>
            <FormBoldTitle bigger>
              Accords
            </FormBoldTitle>
            <TwoGridContainer>
              {
                compensationDamagePositionList.map((accord, index) => (
                  <CheckableButton
                    key={`${accord.code}-${index}`}
                    checked={indemnisation?.accord?.code === accord.code}
                    onChange={() => handleValue(`${personIndexName}.accord.code`, accord.code)}
                    type="radio"
                    label={accord.libelle}
                    disabled={disabled}
                  />
                ))
              }
            </TwoGridContainer>
          </div>
      }
      <FormBoldTitle bigger>
        Régime fiscal
      </FormBoldTitle>
      <SegmentedButtons
        options={regimeOptions}
        selectedOption={indemnisation?.regime?.code}
        setSelectedOption={(newVal) => handleValue(`${personIndexName}.regime.code`, newVal)}
        disabled={disabled}
        smaller
      />
      {
        sinappsGarantieList.length > 0 &&
          <>
            <FormBoldTitle bigger>
              Garanties
            </FormBoldTitle>
            <GarantieTitle>
              Garanties en jeu
              <AddGarantieButton
                variant="contained"
                onClick={addGarantie}
                disabled={disabled || !sinappsGarantieList.some((list) => !garanties.some((gar) => gar.code?.code === list.code ))}
              >
                Ajouter des garanties
              </AddGarantieButton>
            </GarantieTitle>
            {
              garanties.map((garantie, index) => (
                <CardContainer key={`garantie-${index}`}>
                  <CardContent>
                    <GarantieGridContainer>
                      <Select
                        value={garantie.code.code || ''}
                        onChange={(e) => onChangeGarantieName(e.target.value, index)}
                        fullWidth
                        size="small"
                        disabled={disabled}
                      >
                        {
                          sinappsGarantieList
                            .filter((list) => !garanties.some((gar, garIndex) => garIndex !== index && gar.code.code === list.code))
                            .map((value, index) => (
                              <MenuItem
                                value={value.code}
                                key={`${value.code}-${index}`}
                              >
                                {value.libelle}
                              </MenuItem>
                            ))
                        }
                      </Select>
                      <SegmentedButtons
                        options={garantieOptions}
                        selectedOption={garantie.acceptation?.code}
                        setSelectedOption={(newVal) => handleValue(`garanties.[${index}].acceptation.code`, newVal)}
                        disabled={disabled}
                        smaller
                      />
                    </GarantieGridContainer>
                    <GarantieGridContainer>
                      <FormBoldTitle smaller>
                        Estimation des dégâts non couverts
                      </FormBoldTitle>
                      <FormBoldTitle smaller>
                        Commentaire
                      </FormBoldTitle>
                    </GarantieGridContainer>
                    <GarantieGridContainer>
                      <PriceField
                        name={`garanties.[${index}].montantDegatsNonCouverts`}
                        disabled={disabled}
                        size="small"
                        value={garanties[index].montantDegatsNonCouverts}
                        onChange={(e) => handleValue(`garanties.[${index}].montantDegatsNonCouverts`, handleNumberVerification(e.target.value, 2))}
                      />
                      <Field
                        component={TextField}
                        size="small"
                        placeholder="Votre commentaire"
                        name={`garanties.[${index}].commentaire`}
                        disabled={disabled}
                      />
                    </GarantieGridContainer>
                  </CardContent>
                </CardContainer>
              ))
            }
          </>
      }
      {
        indemnisation?.indemnisations && indemnisation.indemnisations?.length > 0 &&
          <CompensationPersonCompensationTable
            indemnisations={indemnisation.indemnisations}
            sinappsGarantieList={sinappsGarantieList}
            garanties={garanties}
            personIndexName={personIndexName}
            handleValue={handleValue}
            disabled={disabled}
          />
      }
      {
        indemnisation?.dommagesSinapps && indemnisation.dommagesSinapps?.length > 0 &&
          <CompensationPersonSinappsDamageTable
            dommagesSinapps={indemnisation.dommagesSinapps}
            personIndexName={personIndexName}
            isHT={indemnisation.regime?.code === RegimeFiscal.HT}
            disabled={disabled}
            handleValue={handleValue}
          />
      }
      {
        indemnisation?.dommagesBienSinapps && indemnisation.dommagesBienSinapps?.length > 0 &&
          <CompensationPersonSinappsPropertyDamageTable
            dommagesBienSinapps={indemnisation.dommagesBienSinapps}
            personIndexName={personIndexName}
            isHT={indemnisation.regime?.code === RegimeFiscal.HT}
            disabled={disabled}
            handleValue={handleValue}
          />
      }
      {
        indemnisation?.indemnisationsSinapps && indemnisation.indemnisationsSinapps?.length > 0 &&
          <CompensationPersonSinappsCompensationTable
            indemnisationsSinapps={indemnisation.indemnisationsSinapps}
            personIndexName={personIndexName}
            isHT={indemnisation.regime?.code === RegimeFiscal.HT}
            garanties={garanties}
            disabled={disabled}
            handleValue={handleValue}
          />
      }
    </Container>
  )
}

export default CompensationPerson
