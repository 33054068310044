/* Framework imports -------------------------------------------------------- */
import React from 'react'
import { Outlet } from 'react-router-dom'

/* Module imports ----------------------------------------------------------- */
import {
  useGetInternalExchangesCountQuery,
  useGetNotificationCountQuery,
  useGetTaskCountQuery,
} from 'store/api'
import { useAuthInfo } from 'store/hooks'
import { useMobileStatusBarStyle } from 'helpers/hooks/useMobileStatusBarStyle'

/* Component imports -------------------------------------------------------- */
import ErrorBoundaryPage from 'layouts/MainLayout/ErrorBoundaryPage/ErrorBoundaryPage'
import BaseLayout from 'layouts/BaseLayout/BaseLayout'
import QuickActionsModal from 'layouts/MainLayout/QuickActions/QuickActionsModal'
import CaseSidebar from 'layouts/CaseSidebar/CaseSidebar'
import MediaLibraryBottomSheet from 'layouts/MainLayout/MediaLibraryBottomSheet/MediaLibraryBottomSheet'
import NavBar from './NavBar/NavBar'
import InternetStatusBanner from './InternetStatusBanner/InternetStatusBanner'

/* Component declaration ---------------------------------------------------- */
interface MainLayoutProps {}

const MainLayout: React.FC<MainLayoutProps> = () => {
  const authInfo = useAuthInfo()
  useMobileStatusBarStyle('black')

  const { currentData: internalExchanges = 0 } = useGetInternalExchangesCountQuery({ destinataire: authInfo?.currentCollaborateur?.refAnnuaire.refComplete || '', limit: 100 })
  const { currentData: notifications = 0 } = useGetNotificationCountQuery({ destinataire: authInfo?.currentCollaborateur?.refAnnuaire.refComplete || '', limit: 100 })
  const { currentData: tasks = 0 } = useGetTaskCountQuery({ collaborateur: authInfo?.currentCollaborateur?.refAnnuaire.refComplete || '', limit: 100 })

  return (
    <BaseLayout>
      <InternetStatusBanner />
      <CaseSidebar />
      <ErrorBoundaryPage>
        <Outlet />
        <QuickActionsModal />
        <MediaLibraryBottomSheet />
      </ErrorBoundaryPage>
      <NavBar
        exchangesCount={internalExchanges}
        tasksCount={tasks}
        notificationsCount={notifications}
      />
    </BaseLayout>
  )
}

export default MainLayout
