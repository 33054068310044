/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'

/* Module imports ----------------------------------------------------------- */
import { selectNetwork } from 'store/slices/networkSlice'
import { useAppSelector } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import {
  ChecklistRtlOutlined,
  BrowserUpdatedOutlined,
} from '@mui/icons-material'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import DownloadOfflineActive from './DownloadOfflineActive'

/* Type imports ------------------------------------------------------------- */
import type { ButtonProps } from '@mui/material'
import type { NetworkState } from 'store/slices/networkSlice'

/* Component declaration ---------------------------------------------------- */
interface DownloadOfflineCaseButtonProps extends ButtonProps {
  caseId: string;
  disabled?: boolean;
}

const DownloadOfflineCaseButton: React.FC<DownloadOfflineCaseButtonProps> = ({ caseId, disabled = false, ...rest }) => {
  const { offlineCases = []}: NetworkState = useAppSelector(selectNetwork)
  const [ active, setActive ] = useState<boolean>(false)

  useEffect(() => {
    if (offlineCases.includes(caseId)) {
      setActive(false)
    }
  }, [ offlineCases ])

  return (
    <>
      <CustomIconButton
        Icon={offlineCases.includes(caseId) ? ChecklistRtlOutlined : BrowserUpdatedOutlined}
        onClick={(e) => {e.stopPropagation(); setActive(true)}}
        onTouchStart={(e) => {e.stopPropagation(); setActive(true)}}
        disabled={disabled || active || offlineCases.includes(caseId)}
        {...rest}
      />
      {active && <DownloadOfflineActive caseId={caseId} />}
    </>
  )
}

export default DownloadOfflineCaseButton
