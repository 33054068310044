/* Module imports ----------------------------------------------------------- */
import {
  createSinappsGUID,
  getIdGarantie,
} from './IndemnisationSinnapsHelper'

/* Type imports ------------------------------------------------------------- */
import {
  TypeDommageSinapps,
  type Dommage, 
} from './types/Dommage'
import type { Indemnisation } from './types/Indemnisation'
import type {
  DommageSinapps,
  DommageSinappsExistant,
} from './types/DommageSinapps'

/* Function declaration ----------------------------------------------------- */
export const getListeDommagesSinapps = (dommages: Dommage[], indemnisations: Indemnisation[], existingDommagesSinapps: DommageSinappsExistant[]): DommageSinapps[] => {
  const dommagesSinapps: DommageSinapps[] = []
  const natureSinappsGUIDdictionary: { [key: string]: string } = {}

  dommages.forEach((dmg) => {
    if (dmg.typeDommageSinapps === TypeDommageSinapps.Dommage && dmg.montantTTC > 0) {
      if (!natureSinappsGUIDdictionary[dmg.codeNatureSinapps]) {
        natureSinappsGUIDdictionary[dmg.codeNatureSinapps] = createSinappsGUID()
      }
      const existingDommage = existingDommagesSinapps.find((d) => d.idDetailDommageSinapps === dmg.idDetailDommageSinapps)

      dommagesSinapps.push({
        ...dmg,
        montantVetusteHT: dmg.montantVetuste / (1 + dmg.tauxTva / 100),
        montantVetusteTTC: dmg.montantVetuste,
        montantVetusteRecuperableHT: dmg.montantVetusteRecuperable / (1 + dmg.tauxTva / 100),
        montantVetusteRecuperableTTC: dmg.montantVetusteRecuperable,
        montantVetusteDeduiteHT: dmg.montantVetusteDeduite / (1 + dmg.tauxTva / 100),
        montantVetusteDeduiteTTC: dmg.montantVetusteDeduite,
        plafond: existingDommage?.plafond,
        idDommageSinapps: natureSinappsGUIDdictionary[dmg.codeNatureSinapps],
        idDetailDommageSinapps: dmg.idDetailDommageSinapps ?? createSinappsGUID(),
        idGarantie: getIdGarantie(dmg, indemnisations),
        modeIndemnisation: dmg.typeReparation,
      })
    }
  })

  return dommagesSinapps
}
