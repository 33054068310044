/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  usePostSinappsVigilanceReportMutation,
  useGetSinappsVigilanceReportListQuery,
  useDeleteSinappsVigilanceReportMutation,
  useGetSinappsVigilanceReportAlertQualificationListQuery,
  useGetSinappsVigilanceReportDisasterContextListQuery,
  useGetSinappsVigilanceReportStakeholderBehaviourListQuery,
  useGetSinappsVigilanceReportCaseContextListQuery,
  useGetSinappsVigilanceReportContractElementListQuery,
  useGetSinappsVigilanceReportExagerationListQuery,
  useGetSinappsVigilanceReportSupportingDocumentListQuery,
  useGetCaseDocumentsQuery,
  useGetSinappsDocumentTypeListQuery,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'
import DateUtils from 'helpers/DateUtils'
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  Checkbox,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material'
import { Delete } from '@mui/icons-material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { toast } from 'react-toastify'
import PageContainer from 'layouts/PageContainer/PageContainer'
import HeaderAction from 'layouts/MainLayout/Headers/HeadersComponents/HeaderAction'
import Loader from 'components/Loader/Loader'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import Tabs from 'components/Tabs/Tabs'
import QaSinappsVigilanceReportCard from './QaSinappsVigilanceReportComponents/QaSinappsVigilanceReportCard'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { ApiResponse } from 'helpers/fetchHelpers'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  CodeLabel,
  DocumentAvecTypeRequest,
  FicheVigilance,
  FicheVigilanceRequest,
  TypeDocumentSinapps,
  TypeDocumentSinappsEnumLabel,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const vigilanceReportSchema = Yup.object().shape<Shape<FicheVigilanceRequest>>({
  codeQualificationAlerte: Yup.string().required("La qualification de l'alerte est obligatoire"),
}).required()

export type NewFicheVigilanceRequest = FormikContextType<FicheVigilanceRequest>

/* Styled components -------------------------------------------------------- */
const TableCard = styled(Card)`
  font-size: 14px;
  padding: 0px 20px;
  margin: 10px 0px 20px;
`

const TableBoldUppercaseTitle = styled(FormBoldTitle)`
  text-transform: uppercase;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
`

interface TableRowProps {
  border?: boolean;
}

const TableRow = styled.div<TableRowProps>`
  height: 100%;
  display: grid;
  gap: 10px;
  padding: 5px 0px;
  justify-content: stretch;
  align-items: center;
  border-bottom: ${(props) => props.border ? `1px solid ${props.theme.colors.grey}` : undefined};
  grid-template-columns: repeat(2, 1fr) 3fr 1fr auto;
  width: 100%;
`

const FileTableRow = styled(TableRow)`
  grid-template-columns: 40px repeat(5, 1fr);
`

const TableBoldSeparator = styled.div`
  width: calc(100% + 40px);
  margin-left: -20px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

const List = styled.ul`
  margin-top: 0px;
  margin-bottom: 5px;
`

const ActionConatiner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const HeaderTabs = styled(Tabs)`
  margin-bottom: -13px;

  button {
    font-size: 1.1rem;
  }
`

/* Component declaration ---------------------------------------------------- */
interface QaSinappsVigilanceReportPageProps {}

const QaSinappsVigilanceReportPage: React.FC<QaSinappsVigilanceReportPageProps> = () => {
  const { caseId = '' } = useParams<{caseId: string}>()
  const [ tabValue, setTabValue ] = useState<number>(0)

  const initialValues: FicheVigilanceRequest = {
    codeQualificationAlerte: '',
    codesCirconstanceSinistre: [],
    codesComportementIntervenant: [],
    codesContexte: [],
    codesElementContrat: [],
    codesExageration: [],
    codesPieceJustificative: [],
    commentaire: '',
    documents: [],
  }

  const {
    currentData: reportList = [],
    isFetching: isFetchingReportList,
  } = useGetSinappsVigilanceReportListQuery(caseId)
  const {
    currentData: alertQualificationList = [],
    isFetching: isFetchingAlertQualificationList,
  } = useGetSinappsVigilanceReportAlertQualificationListQuery()
  const {
    currentData: disasterContextList = [],
    isFetching: isFetchingdisasterContextList,
  } = useGetSinappsVigilanceReportDisasterContextListQuery()
  const {
    currentData: stakeholderBehaviourList = [],
    isFetching: isFetchingStakeholderBehaviourList,
  } = useGetSinappsVigilanceReportStakeholderBehaviourListQuery()
  const {
    currentData: caseContextList = [],
    isFetching: isFetchingCaseContextList,
  } = useGetSinappsVigilanceReportCaseContextListQuery()
  const {
    currentData: contractElementList = [],
    isFetching: isFetchingContractElementList,
  } = useGetSinappsVigilanceReportContractElementListQuery()
  const {
    currentData: exagerationList = [],
    isFetching: isFetchingExagerationList,
  } = useGetSinappsVigilanceReportExagerationListQuery()
  const {
    currentData: supportingDocumentList = [],
    isFetching: isFetchingSupportingDocumentList,
  } = useGetSinappsVigilanceReportSupportingDocumentListQuery()
  const {
    currentData: documentList = [],
    isFetching: isFetchingDocumentList,
  } = useGetCaseDocumentsQuery({ dossier: caseId })
  const {
    currentData: documentTypeList = [],
    isFetching: isFetchingDocumentTypeList,
  } = useGetSinappsDocumentTypeListQuery()
  const [
    submitNewVigilanceReport,
  ] = usePostSinappsVigilanceReportMutation()
  const [
    submitDeleteVigilanceReport,
    { isLoading: isDeletingReport },
  ] = useDeleteSinappsVigilanceReportMutation()

  const onSubmit = async (values: FicheVigilanceRequest, { resetForm, setSubmitting }: FormikHelpers<FicheVigilanceRequest>) => {
    const onFinish = (response: ApiResponse<boolean>): void => {
      if (!isApiError(response)) {
        toast.success(`La fiche de vigilance à bien été envoyée.`)
      } else {
        toast.error(`Une erreur est survenue lors de l'envoi de la fiche de vigilance. Vérifiez qu'au moins un élément est sélectionné.`)
      }
    }

    await submitNewVigilanceReport({
      caseId,
      data: values,
    }).then(onFinish).catch(console.error)

    setSubmitting(false)
    resetForm({ values: { ...initialValues, codeQualificationAlerte: alertQualificationList[0].code }})
  }

  const onDelete = async (id: string) => {
    await submitDeleteVigilanceReport({ caseId, id }).catch(console.error)
  }

  const formikForm: NewFicheVigilanceRequest = useForm<FicheVigilanceRequest>(
    {
      initialValues,
      onSubmit: onSubmit,
      validationSchema: vigilanceReportSchema,
    },
  )

  useEffect(() => {
    if (!isFetchingAlertQualificationList && alertQualificationList[0]?.code) {
      formikForm.setFieldValue('codeQualificationAlerte', alertQualificationList[0].code)
    }
  }, [ alertQualificationList, isFetchingAlertQualificationList ])

  const isLoading = useMemo(() => isFetchingReportList || isFetchingAlertQualificationList || isFetchingdisasterContextList || isFetchingStakeholderBehaviourList || isFetchingCaseContextList || isFetchingContractElementList || isFetchingExagerationList || isFetchingSupportingDocumentList || isFetchingDocumentList || isFetchingDocumentTypeList || formikForm.isSubmitting,
    [
      isFetchingReportList,
      isFetchingAlertQualificationList,
      isFetchingdisasterContextList,
      isFetchingStakeholderBehaviourList,
      isFetchingCaseContextList,
      isFetchingContractElementList,
      isFetchingExagerationList,
      isFetchingSupportingDocumentList,
      isFetchingDocumentList,
      isFetchingDocumentTypeList,
      formikForm.isSubmitting,
    ])

  const qualificationOptions: SegmentedButtonOption<string>[] = alertQualificationList.map(({ code, libelle }) => ({ value: code, label: libelle }))

  const choices: {title: string; selectList: CodeLabel[]; name: keyof FicheVigilanceRequest; elementName: keyof FicheVigilance}[] = useMemo(() => [
    {
      title: 'Circonstance du sinistre',
      selectList: disasterContextList,
      name: 'codesCirconstanceSinistre',
      elementName: 'circonstanceSinistre',
    },
    {
      title: 'Comportement intervenant',
      selectList: stakeholderBehaviourList,
      name: 'codesComportementIntervenant',
      elementName: 'comportementIntervenant',
    },
    {
      title: 'Contexte du dossier',
      selectList: caseContextList,
      name: 'codesContexte',
      elementName: 'contexteDossier',
    },
    {
      title: 'Élément du contrat',
      selectList: contractElementList,
      name: 'codesElementContrat',
      elementName: 'elementContrat',
    },
    {
      title: 'Exagération',
      selectList: exagerationList,
      name: 'codesExageration',
      elementName: 'exageration',
    },
    {
      title: 'Pièce justificative',
      selectList: supportingDocumentList,
      name: 'codesPieceJustificative',
      elementName: 'pieceJustificative',
    },
  ], [ isLoading ])

  const handleValue = (name: string, value: string | string[] | DocumentAvecTypeRequest[]) => formikForm.setFieldValue(name, value).catch(console.error)

  const handleChecked = (name: keyof FicheVigilanceRequest, value: string, checked: boolean): void => {
    const newValues = [ ...(formikForm.values[name] || []) ] as string[]

    if (!checked) {
      handleValue(name, newValues.filter((val) => val !== value))
    }
    else {
      newValues.push(value)
      handleValue(name, newValues)
    }
  }

  const handleFileCheckAll = (): void => {
    if (formikForm.values.documents?.length === documentList.length && formikForm.values.documents.length > 0) {
      handleValue('documents', [])
    } else {
      handleValue('documents', documentList.map((doc): DocumentAvecTypeRequest => ({ idFichier: doc.id || '', type: doc.categorieSinapps?.code })))
    }
  }

  const handleFileChecked = (fileId: string, fileType?: TypeDocumentSinappsEnumLabel): void => {
    if (formikForm.values.documents?.some((doc) => doc.idFichier === fileId)) {
      handleValue('documents', [ ...formikForm.values.documents.filter((doc) => doc.idFichier !== fileId) ])
    } else {
      handleValue('documents', [ ...formikForm.values.documents || [], { idFichier: fileId, type: fileType?.code } ])
    }
  }

  const modifyFileType = (fileId: string, fileType: string): void => {
    handleValue('documents', [ ...(formikForm.values.documents || []).filter((doc) => doc.idFichier !== fileId), { idFichier: fileId, type: fileType as TypeDocumentSinapps } ])
  }

  return (
    <>
      <HeaderAction
        title={
          <HeaderTabs
            value={tabValue}
            onChange={(e, v) => setTabValue(v)}
            tabs={[ 'Liste des fiches de vigilances', 'Nouvelle fiche' ]}
          />
        }
        onSubmit={formikForm.handleSubmit}
      >
        {
          tabValue === 1 &&
            <SubmitFormButton
              type="submit"
              variant="contained"
              disabled={isLoading}
            >
              Envoyer
            </SubmitFormButton>
        }
      </HeaderAction>
      <PageContainer>
        {isLoading && <Loader />}
        {
          tabValue === 0 &&
            <TableCard>
              <TableBoldUppercaseTitle>
                <TableRow>
                  <div>
                    Date de création
                  </div>
                  <div>
                    Qualification
                  </div>
                  <div>
                    Élements
                  </div>
                  <div>
                    Fichiers
                  </div>
                  <div>
                    Action
                  </div>
                </TableRow>
              </TableBoldUppercaseTitle>
              <TableBoldSeparator />
              {
                [ ...reportList ].sort((a, b) => b.dateCreation.localeCompare(a.dateCreation)).map((value, index) => (
                  <TableRow
                    border={index !== (reportList.length || 0) -1}
                    key={`${value.guidVigilance}-${index}`}
                  >
                    <div>
                      {DateUtils.APIStrToLocalDateString(value.dateCreation)}
                    </div>
                    <div>
                      {value.qualificationAlerte.libelle || value.qualificationAlerte.code}
                    </div>
                    <div>
                      {
                        choices.map((choice) =>
                          ((value[choice.elementName] as CodeLabel[])?.length || 0) > 0 && (
                            <React.Fragment key={choice.name}>
                              <b>
                                {choice.title}
                              </b>
                              <List>
                                {
                                  (value[choice.elementName] as CodeLabel[])?.map((v) => (
                                    <li key={v.code}>
                                      {v.libelle}
                                    </li>
                                  ))
                                }
                              </List>
                            </React.Fragment>
                          ),
                        )
                      }
                    </div>
                    <ul>
                      {
                        value.pieceJointes?.map((v, i) => (
                          <li key={`${v.id}-${i}`}>
                            <a
                              href={v.url || ''}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              {v.libelle}
                            </a>
                          </li>
                        ),
                        )
                      }
                    </ul>
                    <ActionConatiner>
                      <Tooltip title={value.ficheSupprimee ? 'Fiche supprimée' : 'Supprimer la fiche'}>
                        <IconButton
                          disabled={isDeletingReport}
                          onClick={() => value.ficheSupprimee ? null : onDelete(value.guidVigilance || '')}
                        >
                          <Delete color={value.ficheSupprimee ? 'disabled' : 'error'} />
                        </IconButton>
                      </Tooltip>
                    </ActionConatiner>
                  </TableRow>
                ))
              }
            </TableCard>
        }
        {
          tabValue === 1 &&
            <Form form={formikForm}>
              <FormBoldTitle required>
                Qualification de l'alerte de vigilance
              </FormBoldTitle>
              <SegmentedButtons
                options={qualificationOptions}
                selectedOption={formikForm.values.codeQualificationAlerte}
                setSelectedOption={(newVal) => handleValue(`codeQualificationAlerte`, newVal)}
              />
              {
                choices.map(({ title, selectList, name }, index) => (
                  <QaSinappsVigilanceReportCard
                    key={`${title}-${index}`}
                    title={title}
                    amountChecked={(formikForm.values[name] as string []).length}
                    checkedList={formikForm.values[name] as string []}
                    handleChecked={(value: string, checked: boolean) => handleChecked(name, value, checked)}
                    selectList={selectList}
                  />
                ))
              }
              <FormBoldTitle>
                Commentaire
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Votre message"
                name="commentaire"
                rows={3}
                multiline
              />
              <TableCard>
                <TableBoldUppercaseTitle>
                  <FileTableRow>
                    <Checkbox
                      onChange={handleFileCheckAll}
                      checked={formikForm.values.documents?.length === documentList.length && documentList.length > 0}
                    />
                    <div>
                      Libellé
                    </div>
                    <div>
                      Date de création
                    </div>
                    <div>
                      Nom Document
                    </div>
                    <div>
                      Type
                    </div>
                    <div>
                      Origine
                    </div>
                  </FileTableRow>
                </TableBoldUppercaseTitle>
                <TableBoldSeparator />
                {
                  documentList.map((document, index) => (
                    <FileTableRow
                      border={index !== (documentList.length || 0) -1}
                      key={`${document.id}-${index}`}
                    >
                      <Checkbox
                        checked={formikForm.values.documents?.some((doc) => doc.idFichier === document.id)}
                        onChange={() => handleFileChecked(document.id ?? document.libelle, document.categorieSinapps)}
                      />
                      <div>
                        {document.libelle}
                      </div>
                      <div>
                        {DateUtils.APIStrToLocalDateString(document.dateCreation)}
                      </div>
                      <div>
                        {document.fileName}
                      </div>
                      {
                        isValidString(document.categorieSinapps?.code) &&
                          <Select
                            value={formikForm.values.documents?.find((doc) => doc.idFichier === document.id)?.type ?? document.categorieSinapps?.code}
                            onChange={(e): void => modifyFileType(document.id ?? document.libelle, e.target.value)}
                            fullWidth
                            displayEmpty
                            size="small"
                            disabled={isFetchingDocumentTypeList}
                          >
                            {
                              documentTypeList.map(({ code, libelle }) => (
                                <MenuItem
                                  value={code}
                                  key={code}
                                >
                                  {libelle}
                                </MenuItem>
                              ))
                            }
                          </Select>
                      }
                      <div>
                        {document.origine}
                      </div>
                    </FileTableRow>
                  ))
                }
              </TableCard>
            </Form>
        }
      </PageContainer>
    </>
  )
}

export default QaSinappsVigilanceReportPage
