/* Module imports ----------------------------------------------------------- */
import { v4 as uuid } from 'uuid'

/* Type imports ------------------------------------------------------------- */
import type { Dommage } from './types/Dommage'
import type { Indemnisation } from './types/Indemnisation'
import type { IndemnisationSinapps } from './types/IndemnisationSinapps'

/* Function declaration ----------------------------------------------------- */
export const createSinappsGUID = (): string => {
  return uuid()
}

export const getIdGarantie = (dommage: Dommage, indemnisations: Indemnisation[]): string | undefined => {
  const indemnisation = indemnisations.find((indemn) =>
    (indemn.codeNature === dommage.codeNature || indemn.codeNatureSinapps === dommage.codeNatureSinapps) &&
    (indemn.codeDetail === dommage.codeDetail || indemn.codeDetailSinapps === dommage.codeDetailSinapps) &&
    indemn.typeReparation === dommage.typeReparation,
  )

  if (!indemnisation) return
  return indemnisation.idGarantie
}

export const valueOrZero = (number?: number): number => {
  if (!number || number === null) {
    return 0
  }

  return number
}

export const roundNumber = (number: number) => {
  const roundedNumber: number = +number.toFixed(2)
  return roundedNumber
}

export const roundValues = (indemnisationSinapps: IndemnisationSinapps): IndemnisationSinapps => {
  Object.keys(indemnisationSinapps).forEach((key) => {
    const value = indemnisationSinapps[key as keyof IndemnisationSinapps]

    if (typeof value === 'number' && value !== null) {
      (indemnisationSinapps[key as keyof IndemnisationSinapps] as number) = roundNumber(value)
    }
  })
  return indemnisationSinapps
}
