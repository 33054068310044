/* Type imports ------------------------------------------------------------- */
import type { Dommage } from './types/Dommage'
import type {
  Indemnisation,
  IndemnisationExistante,
} from './types/Indemnisation'

/* Function declaration ----------------------------------------------------- */
export const calculIndemnisation = (dommages: Dommage[], existingIndemnisations: IndemnisationExistante[]): Indemnisation[] => {
  const indemnisations: Indemnisation[] = []

  dommages.forEach((dmg) => {
    let indemEnCours: Indemnisation | undefined = undefined
    const montantDecouvert = dmg.montantVetuste > dmg.montantVetusteRecuperable ? dmg.montantVetuste - dmg.montantVetusteRecuperable : 0

    if (indemnisations.length > 0) {
      indemEnCours = indemnisations.find((indemn) =>
        indemn.codeNature === dmg.codeNature &&
        indemn.codeDetail === dmg.codeDetail &&
        indemn.typeReparation === dmg.typeReparation,
      )
    }
    const existing = existingIndemnisations.find((indemn) =>
      (indemn.codeNature === dmg.codeNature || indemn.codeNatureSinapps === dmg.codeNatureSinapps) &&
      (indemn.codeDetail === dmg.codeDetail || indemn.codeDetailSinapps === dmg.codeDetailSinapps) &&
      indemn.typeReparation === dmg.typeReparation,
    )
    if (!indemEnCours || indemnisations.length === 0) {
      const idGarantie = existing ? existing.idGarantie : ''

      indemnisations.push({
        ...dmg,
        montantVetusteDeduiteTTC: dmg.montantVetusteDeduite,
        montantDecouvert,
        idGarantie: idGarantie,
      })
    } else {
      const indexIdem = indemnisations.findIndex((indemn) =>
        indemn.codeNature === dmg.codeNature &&
        indemn.codeDetail === dmg.codeDetail &&
        indemn.typeReparation === dmg.typeReparation,
      )
      indemnisations[indexIdem].montantHT += dmg.montantHT
      indemnisations[indexIdem].montantTTC += dmg.montantTTC
      indemnisations[indexIdem].montantTva += dmg.montantTva
      indemnisations[indexIdem].montantVetusteDeduiteTTC += dmg.montantVetusteDeduite
      indemnisations[indexIdem].montantVetusteRecuperable += dmg.montantVetusteRecuperable
      indemnisations[indexIdem].montantVetuste += dmg.montantVetuste
      indemnisations[indexIdem].montantDecouvert += montantDecouvert
    }
  })

  return indemnisations
}
