/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import PageContainer from './PageContainer'

/* Component declaration ---------------------------------------------------- */
const CenteredPageContainer = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export default CenteredPageContainer
