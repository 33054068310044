/* Module imports ----------------------------------------------------------- */
import {
  useAppSelector,
  useAppDispatch,
} from 'store/hooks'
import {
  closeMediaLibrary,
  closeMediaSidebar,
  openMediaLibrary,
  openMediaSidebar,
  selectMediaLibrary,
  setMediaLibraryOpenState,
} from 'store/slices/mediaLibrarySlice'

/* Type imports ------------------------------------------------------------- */
import type { MediaLibraryState } from 'store/slices/mediaLibrarySlice'
import type { PieceJointe } from 'API/__generated__/Api'

/* Hook declarations -------------------------------------------------------- */
export const useMediaLibrary = () => {
  const dispatch = useAppDispatch()

  const openMediaLibraryDispatch = (document: PieceJointe): {payload: PieceJointe; type: string} => dispatch(openMediaLibrary(document))
  const closeMediaLibraryDispatch = (): {payload: undefined; type: string} => dispatch(closeMediaLibrary())
  const setMediaLibraryOpenStateDispatch = (pOpen: boolean): {payload: boolean; type: string} => dispatch(setMediaLibraryOpenState(pOpen))
  const openMediaSidebarDispatch = (): {payload: undefined; type: string} => dispatch(openMediaSidebar())
  const closeMediaSidebarDispatch = (): {payload: undefined; type: string} => dispatch(closeMediaSidebar())

  const libraryState: MediaLibraryState = useAppSelector(selectMediaLibrary)

  return {
    openMediaLibrary: openMediaLibraryDispatch,
    closeMediaLibrary: closeMediaLibraryDispatch,
    setMediaLibraryOpen: setMediaLibraryOpenStateDispatch,
    openMediaSidebar: openMediaSidebarDispatch,
    closeMediaSidebar: closeMediaSidebarDispatch,
    state: libraryState,
  }
}
