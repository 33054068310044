/* Module imports ----------------------------------------------------------- */
import {
  createSinappsGUID,
  getIdGarantie,
} from './IndemnisationSinnapsHelper'

/* Type imports ------------------------------------------------------------- */
import {
  TypeDommageSinapps,
  type Dommage, 
} from './types/Dommage'
import type { Indemnisation } from './types/Indemnisation'
import type {
  DommageBienSinapps,
  DommageBienSinappsExistant,
} from './types/DommageBienSinapps'

/* Function declaration ----------------------------------------------------- */
export const getListeBiensSinapps = (dommages: Dommage[], indemnisations: Indemnisation[], existingBienSinapps: DommageBienSinappsExistant[]): DommageBienSinapps[] => {
  const biensSinapps: DommageBienSinapps[] = []
  const natureSinappsGUIDdictionary: { [key: string]: string } = {}

  dommages.forEach((dmg) => {
    if (dmg.typeDommageSinapps === TypeDommageSinapps.Bien && dmg.montantTTC > 0) {
      if (!natureSinappsGUIDdictionary[dmg.codeNatureSinapps]) {
        natureSinappsGUIDdictionary[dmg.codeNatureSinapps] = createSinappsGUID()
      }
      const existingBien = existingBienSinapps.find((d) => d.idDetailDommageSinapps === dmg.idDetailDommageSinapps)

      biensSinapps.push({
        ...dmg,
        montantVetusteHT: dmg.montantVetuste / (1 + dmg.tauxTva / 100),
        montantVetusteTTC: dmg.montantVetuste,
        montantVetusteRecuperableHT: dmg.montantVetusteRecuperable / (1 + dmg.tauxTva / 100),
        montantVetusteRecuperableTTC: dmg.montantVetusteRecuperable,
        plafond: existingBien?.plafond,
        idDommageSinapps: natureSinappsGUIDdictionary[dmg.codeNatureSinapps],
        idDetailDommageSinapps: dmg.idDetailDommageSinapps ?? createSinappsGUID(),
        idGarantie: getIdGarantie(dmg, indemnisations),
      })
    }
  })

  return biensSinapps
}
