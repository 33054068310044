/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useOutletContext,
  useParams,
} from 'react-router-dom'
import {
  useGetQualiteListQuery,
  useGetRiskNotVerifiedReasonListQuery,
  useGetRiskTypeListQuery,
  useGetRiskUsageListQuery,
} from 'store/api'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'
import { getAddress } from 'helpers/getAddress'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
  MenuItem,
} from '@mui/material'
import { Field } from 'formik'
import {
  CheckboxWithLabel,
  Select,
  TextField,
} from 'formik-mui'
import Loader from 'components/Loader/Loader'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import TravelerLargeTitle from 'components/TravelerLargeTitle/TravelerLargeTitle'
import RiskComplianceContractInfo from './RiskComplianceContractInfo/RiskComplianceContractInfo'
import RiskComplianceCorrectInfoModal from './RiskComplianceCorrectInfoModal/RiskComplianceCorrectInfoModal'

/* Type imports ------------------------------------------------------------- */
import {
  TypeRexSinistre,
  Verif,
} from 'API/__generated__/Api'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type {
  RiskComplianceModalType,
  RiskComplianceValueType,
} from 'types/RiskCompliance'
import type { TravelerForm } from 'types/TravelerForm'

/* Type declarations -------------------------------------------------------- */
type ModalInfo = {
  label: string;
  fieldName: string;
  modalType: RiskComplianceModalType;
  value: RiskComplianceValueType;
  verificationFieldName: string;
  verificationPreviousValue: Verif;
}

/* Styled components -------------------------------------------------------- */
const ContentContainer = styled(CardContent)`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1.25fr .75fr 1.75fr;
  gap: 5px;
  justify-content: stretch;
  align-items: flex-start;
  padding-bottom: 5px !important;
  padding-top: 5px !important;
  align-items: center;
`

const VerifContentContainer = styled(ContentContainer)`
  grid-template-columns: 1fr 1.45fr .25fr .25fr 1.75fr;
`

const CardContainer = styled(Card)`
  margin-bottom: 10px;
  font-size: 13px;
`

const CardContentContainer = styled(CardContent)`
  margin-top: -35px;
`

interface BoldTextProps {
  grey?: boolean;
}

const BoldText = styled.div<BoldTextProps>`
  font-weight: bold;
  color: ${(props) => props.grey ? '#AAAAAA' : '' };
`

const EndTextField = styled(TextField)`
  margin-bottom: 40px;
`

const MarginBottomTextField = styled(TextField)`
  margin-bottom: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface RiskCompliancePageProps {}

const RiskCompliancePage: React.FC<RiskCompliancePageProps> = () => {
  const formikForm = useOutletContext<TravelerForm>()
  const { caseId = '' } = useParams<{caseId: string}>()
  const [ modalInfo, setModalInfo ] = useState<ModalInfo>()

  const {
    currentData: riskTypeList = [],
    isFetching: isFetchingRiskTypeList,
  } = useGetRiskTypeListQuery()
  const {
    currentData: riskUsageList = [],
    isFetching: isFetchingRiskUsageList,
  } = useGetRiskUsageListQuery()
  const {
    currentData: riskNotVerifiedReasonList = [],
    isFetching: isFetchingRiskNotVerifiedReasonList,
  } = useGetRiskNotVerifiedReasonListQuery()
  const {
    currentData: riskQualiteList = [],
    isFetching: isFetchingRiskQualiteList,
  } = useGetQualiteListQuery(caseId)

  const handleValue = (type: string, value?: string | boolean | RiskComplianceValueType): void => {
    formikForm.setFieldValue(`conformiteDuRisque.${type}`, value)
  }

  const handleValueVerif = (
    type: string,
    value: string,
    label: string,
    fieldName: string,
    modalType: RiskComplianceModalType,
    verificationPreviousValue?: Verif,
    modalValue?: RiskComplianceValueType,
  ): void => {
    formikForm.setFieldValue(`conformiteDuRisque.${type}`, value)
    if (value === 'Non' && modalValue !== undefined) {
      setModalInfo({
        label,
        value: modalValue,
        fieldName,
        modalType,
        verificationPreviousValue: verificationPreviousValue ?? Verif.NonVerifie,
        verificationFieldName: type,
      })
    }
  }

  const handleClose = (value?: RiskComplianceValueType): void => {
    if (modalInfo === undefined) return
    if (value !== undefined) {
      handleValue(modalInfo.fieldName, value)
    }
    else {
      handleValue(modalInfo.verificationFieldName, modalInfo.verificationPreviousValue)
    }
    setModalInfo(undefined)
  }

  const verifyOptions: SegmentedButtonOption<string>[] = [ { value: 'Oui' }, { value: 'Non' }, { value: 'NonVerifie', label: 'Non vérifié' } ]
  const isBcOptions: SegmentedButtonOption<boolean>[] = [ { value: true, label: 'Oui' }, { value: false, label: 'Non' } ]

  if (formikForm.values.conformiteDuRisque === undefined) {
    return null
  }

  return (
    <div>
      {(isFetchingRiskTypeList || isFetchingRiskUsageList || isFetchingRiskQualiteList || isFetchingRiskNotVerifiedReasonList) && <Loader />}
      <TravelerLargeTitle>
        Conformité du risque
      </TravelerLargeTitle>
      {
        formikForm.values.conformiteDuRisque.contratRex &&
          <RiskComplianceContractInfo contractInfo={formikForm.values.conformiteDuRisque.contratRex} />
      }
      <FormBoldTitle bigger>
        Risque
      </FormBoldTitle>
      <CardContainer>
        <ContentContainer>
          Adresse
          <BoldText>
            {formikForm.values.conformiteDuRisque.adresseRisque && getAddress(formikForm.values.conformiteDuRisque.adresseRisque.adresse)}
          </BoldText>
          <div />
          <SegmentedButtons
            options={verifyOptions}
            selectedOption={formikForm.values.conformiteDuRisque.adresseRisque?.verification}
            setSelectedOption={
              (newVal) => handleValueVerif('adresseRisque.verification', newVal,
                'Adresse', 'adresseRisque.adresse', 'address',
                formikForm.values.conformiteDuRisque.adresseRisque?.verification, formikForm.values.conformiteDuRisque.adresseRisque?.adresse)
            }
            smaller
            disabled={formikForm.values.disabled}
          />
        </ContentContainer>
      </CardContainer>
      <CardContainer>
        <ContentContainer>
          Type d'immeuble
          <BoldText>
            {formikForm.values.conformiteDuRisque.type?.type?.libelle}
          </BoldText>
          <div />
          <SegmentedButtons
            options={verifyOptions}
            selectedOption={formikForm.values.conformiteDuRisque.type?.verification}
            setSelectedOption={
              (newVal) => handleValueVerif('type.verification', newVal,
                "Type d'immeuble", 'type.type', 'selectList',
                formikForm.values.conformiteDuRisque.type?.verification, formikForm.values.conformiteDuRisque.type?.type)
            }
            smaller
            disabled={formikForm.values.disabled}
          />
        </ContentContainer>
      </CardContainer>
      <CardContainer>
        <ContentContainer>
          Usage du risque
          <BoldText>
            {formikForm.values.conformiteDuRisque.usage?.usage?.libelle}
          </BoldText>
          <div />
          <SegmentedButtons
            options={verifyOptions}
            selectedOption={formikForm.values.conformiteDuRisque.usage?.verification}
            setSelectedOption={
              (newVal) => handleValueVerif('usage.verification', newVal,
                'Usage du risque', 'usage.usage', 'selectList',
                formikForm.values.conformiteDuRisque.usage?.verification, formikForm.values.conformiteDuRisque.usage?.usage)
            }
            smaller
            disabled={formikForm.values.disabled}
          />
        </ContentContainer>
      </CardContainer>
      <CardContainer>
        <ContentContainer>
          Qualité assuré
          <BoldText>
            {formikForm.values.conformiteDuRisque.qualiteAssure?.qualite?.libelle}
          </BoldText>
          <div />
          <SegmentedButtons
            options={verifyOptions}
            selectedOption={formikForm.values.conformiteDuRisque.qualiteAssure?.verification}
            setSelectedOption={
              (newVal) => handleValueVerif('qualiteAssure.verification', newVal,
                'Qualité assuré', 'qualiteAssure.qualite', 'selectList',
                formikForm.values.conformiteDuRisque.qualiteAssure?.verification, formikForm.values.conformiteDuRisque.qualiteAssure?.qualite)
            }
            smaller
            disabled={formikForm.values.disabled}
          />
        </ContentContainer>
      </CardContainer>
      <CardContainer>
        <VerifContentContainer>
          Vérification du risque
          <BoldText>
            Surface
          </BoldText>
          <BoldText grey={formikForm.values.conformiteDuRisque.verification?.surface?.valeurDeclaree !== formikForm.values.conformiteDuRisque.verification?.surface?.valeurRelevee}>
            {formikForm.values.conformiteDuRisque.verification?.surface?.valeurDeclaree}
          </BoldText>
          <BoldText>
            {
              formikForm.values.conformiteDuRisque.verification?.surface?.valeurRelevee === formikForm.values.conformiteDuRisque.verification?.surface?.valeurDeclaree ?
                '' :
                formikForm.values.conformiteDuRisque.verification?.surface?.valeurRelevee
            }
          </BoldText>
          <SegmentedButtons
            options={verifyOptions}
            selectedOption={formikForm.values.conformiteDuRisque.verification?.surface?.valeurVerifiee}
            setSelectedOption={
              (newVal) => handleValueVerif('verification.surface.valeurVerifiee', newVal,
                'Surface', 'verification.surface.valeurRelevee', 'verification',
                formikForm.values.conformiteDuRisque.verification?.surface?.valeurVerifiee, formikForm.values.conformiteDuRisque.verification?.surface?.valeurDeclaree || 0)
            }
            smaller
            disabled={formikForm.values.disabled}
          />
          <div />
          <BoldText>
            Surface annexe
          </BoldText>
          <BoldText grey={formikForm.values.conformiteDuRisque.verification?.surfaceAnnexe?.valeurDeclaree !== formikForm.values.conformiteDuRisque.verification?.surfaceAnnexe?.valeurRelevee}>
            {formikForm.values.conformiteDuRisque.verification?.surfaceAnnexe?.valeurDeclaree}
          </BoldText>
          <BoldText>
            {
              formikForm.values.conformiteDuRisque.verification?.surfaceAnnexe?.valeurRelevee === formikForm.values.conformiteDuRisque.verification?.surfaceAnnexe?.valeurDeclaree ?
                '' :
                formikForm.values.conformiteDuRisque.verification?.surfaceAnnexe?.valeurRelevee
            }
          </BoldText>
          <SegmentedButtons
            options={verifyOptions}
            selectedOption={formikForm.values.conformiteDuRisque.verification?.surfaceAnnexe?.valeurVerifiee}
            setSelectedOption={
              (newVal) => handleValueVerif('verification.surfaceAnnexe.valeurVerifiee', newVal,
                'Surface annexe', 'verification.surfaceAnnexe.valeurRelevee', 'verification',
                formikForm.values.conformiteDuRisque.verification?.surfaceAnnexe?.valeurVerifiee, formikForm.values.conformiteDuRisque.verification?.surfaceAnnexe?.valeurDeclaree || 0)
            }
            smaller
            disabled={formikForm.values.disabled}
          />
          <div />
          <BoldText>
            Nombre de pièces
          </BoldText>
          <BoldText grey={formikForm.values.conformiteDuRisque.verification?.nombreDePiece?.valeurDeclaree !== formikForm.values.conformiteDuRisque.verification?.nombreDePiece?.valeurRelevee}>
            {formikForm.values.conformiteDuRisque.verification?.nombreDePiece?.valeurDeclaree}
          </BoldText>
          <BoldText>
            {
              formikForm.values.conformiteDuRisque.verification?.nombreDePiece?.valeurRelevee === formikForm.values.conformiteDuRisque.verification?.nombreDePiece?.valeurDeclaree ?
                '' :
                formikForm.values.conformiteDuRisque.verification?.nombreDePiece?.valeurRelevee
            }
          </BoldText>
          <SegmentedButtons
            options={verifyOptions}
            selectedOption={formikForm.values.conformiteDuRisque.verification?.nombreDePiece?.valeurVerifiee}
            setSelectedOption={
              (newVal) => handleValueVerif('verification.nombreDePiece.valeurVerifiee', newVal,
                'Nombre de pièces', 'verification.nombreDePiece.valeurRelevee', 'verification',
                formikForm.values.conformiteDuRisque.verification?.nombreDePiece?.valeurVerifiee, formikForm.values.conformiteDuRisque.verification?.nombreDePiece?.valeurDeclaree || 0)
            }
            smaller
            disabled={formikForm.values.disabled}
          />
          <div />
          <BoldText>
            Nombre d'étages
          </BoldText>
          <BoldText grey={formikForm.values.conformiteDuRisque.verification?.nombreDEtage?.valeurDeclaree !== formikForm.values.conformiteDuRisque.verification?.nombreDEtage?.valeurRelevee}>
            {formikForm.values.conformiteDuRisque.verification?.nombreDEtage?.valeurDeclaree}
          </BoldText>
          <BoldText>
            {
              formikForm.values.conformiteDuRisque.verification?.nombreDEtage?.valeurRelevee === formikForm.values.conformiteDuRisque.verification?.nombreDEtage?.valeurDeclaree ?
                '' :
                formikForm.values.conformiteDuRisque.verification?.nombreDEtage?.valeurRelevee
            }
          </BoldText>
          <SegmentedButtons
            options={verifyOptions}
            selectedOption={formikForm.values.conformiteDuRisque.verification?.nombreDEtage?.valeurVerifiee}
            setSelectedOption={
              (newVal) => handleValueVerif('verification.nombreDEtage.valeurVerifiee', newVal,
                "Nombre d'étages", 'verification.nombreDEtage.valeurRelevee', 'verification',
                formikForm.values.conformiteDuRisque.verification?.nombreDEtage?.valeurVerifiee, formikForm.values.conformiteDuRisque.verification?.nombreDEtage?.valeurDeclaree || 0)
            }
            smaller
            disabled={formikForm.values.disabled}
          />
          <div />
          <BoldText>
            Nombre de dépendances
          </BoldText>
          <BoldText grey={formikForm.values.conformiteDuRisque.verification?.nombreDeDependance?.valeurDeclaree !== formikForm.values.conformiteDuRisque.verification?.nombreDeDependance?.valeurRelevee}>
            {formikForm.values.conformiteDuRisque.verification?.nombreDeDependance?.valeurDeclaree}
          </BoldText>
          <BoldText>
            {
              formikForm.values.conformiteDuRisque.verification?.nombreDeDependance?.valeurRelevee === formikForm.values.conformiteDuRisque.verification?.nombreDeDependance?.valeurDeclaree ?
                '' :
                formikForm.values.conformiteDuRisque.verification?.nombreDeDependance?.valeurRelevee
            }
          </BoldText>
          <SegmentedButtons
            options={verifyOptions}
            selectedOption={formikForm.values.conformiteDuRisque.verification?.nombreDeDependance?.valeurVerifiee}
            setSelectedOption={
              (newVal) => handleValueVerif('verification.nombreDeDependance.valeurVerifiee', newVal,
                'Nombre de dépendances', 'verification.nombreDeDependance.valeurRelevee', 'verification',
                formikForm.values.conformiteDuRisque.verification?.nombreDeDependance?.valeurVerifiee, formikForm.values.conformiteDuRisque.verification?.nombreDeDependance?.valeurDeclaree || 0)
            }
            smaller
            disabled={formikForm.values.disabled}
          />
          <div />
        </VerifContentContainer>
      </CardContainer>
      <br />
      <CardContainer>
        <CardContentContainer>
          <FormBoldTitle bigger>
            Conformité du risque
          </FormBoldTitle>
          <SegmentedButtons
            options={verifyOptions}
            selectedOption={formikForm.values.conformiteDuRisque.conformite?.verification}
            setSelectedOption={(newVal) => handleValue('conformite.verification', newVal)}
            disabled={formikForm.values.disabled}
          />
          {
            formikForm.values.conformiteDuRisque.conformite?.verification === Verif.NonVerifie &&
              <>
                <FormBoldTitle>
                  Motif de non vérification
                </FormBoldTitle>
                <Field
                  component={Select}
                  name="conformiteDuRisque.conformite.motifNonVerification.code"
                  displayEmpty
                  value={formikForm.values.conformiteDuRisque.conformite?.motifNonVerification?.code || ''}
                  renderValue={verifySelectFieldValue(formikForm.values.conformiteDuRisque.conformite?.motifNonVerification?.code)}
                  disabled={isFetchingRiskNotVerifiedReasonList}
                >
                  {
                    riskNotVerifiedReasonList.map((value, index) => (
                      <MenuItem
                        value={value.code}
                        key={`${value.code}-${index}`}
                      >
                        {value.libelle}
                      </MenuItem>
                    ))
                  }
                </Field>
              </>
          }
          {
            formikForm.values.conformiteDuRisque.conformite?.verification === Verif.Non &&
              <>
                <br />
                <div>
                  <Field
                    id="description"
                    component={CheckboxWithLabel}
                    Label={{ label: 'Description du risque erronée' }}
                    type="checkbox"
                    checked={formikForm.values.conformiteDuRisque.conformite.descriptionRisqueErrone ?? false}
                    onChange={(e: React.SyntheticEvent, c: boolean) => formikForm.setFieldValue('conformiteDuRisque.conformite.descriptionRisqueErrone', c)}
                  />
                </div>
                <div>
                  <Field
                    id="garantie"
                    component={CheckboxWithLabel}
                    Label={{ label: 'Garantie et/ou capitaux insuffisants' }}
                    type="checkbox"
                    checked={formikForm.values.conformiteDuRisque.conformite.garantieEtOuCapitauxInsuffisants ?? false}
                    onChange={(e: React.SyntheticEvent, c: boolean) => formikForm.setFieldValue('conformiteDuRisque.conformite.garantieEtOuCapitauxInsuffisants', c)}
                  />
                </div>
                {
                  formikForm.values.conformiteDuRisque.conformite.garantieEtOuCapitauxInsuffisants &&
                    <Field
                      component={MarginBottomTextField}
                      placeholder="Votre message"
                      rows={3}
                      multiline
                      value={formikForm.values.conformiteDuRisque.conformite.garantieEtOuCapitauxInsuffisantsCommentaire || ''}
                      name="conformiteDuRisque.conformite.garantieEtOuCapitauxInsuffisantsCommentaire"
                      disabled={formikForm.values.disabled}
                    />
                }
                <div>
                  <Field
                    id="garantie"
                    component={CheckboxWithLabel}
                    Label={{ label: 'Autre (Clauses, Bail, Éléments spécifiques du contrat...)' }}
                    type="checkbox"
                    checked={formikForm.values.conformiteDuRisque.conformite.autreRejet ?? false}
                    onChange={(e: React.SyntheticEvent, c: boolean) => formikForm.setFieldValue('conformiteDuRisque.conformite.autreRejet', c)}
                  />
                </div>
                {
                  formikForm.values.conformiteDuRisque.conformite.autreRejet &&
                    <Field
                      component={TextField}
                      placeholder="Votre message"
                      rows={3}
                      multiline
                      value={formikForm.values.conformiteDuRisque.conformite.autreRejetCommentaire || ''}
                      name="conformiteDuRisque.conformite.autreRejetCommentaire"
                      disabled={formikForm.values.disabled}
                    />
                }
              </>
          }
          <FormBoldTitle
            required={
              formikForm.values.conformiteDuRisque.conformite?.verification === Verif.NonVerifie &&
              formikForm.values.conformiteDuRisque.conformite?.motifNonVerification?.code === 'Autre'
            }
          >
            Commentaire
          </FormBoldTitle>
          <Field
            component={TextField}
            placeholder="Votre message"
            rows={3}
            multiline
            name="conformiteDuRisque.conformite.commentaire"
            disabled={formikForm.values.disabled}
          />
        </CardContentContainer>
      </CardContainer>
      {
        formikForm.values.sinistre.type?.code === TypeRexSinistre.Vol &&
          <>
            <FormBoldTitle bigger>
              Moyen de protection vol
            </FormBoldTitle>
            <SegmentedButtons
              options={verifyOptions}
              selectedOption={formikForm.values.conformiteDuRisque.protectionVol?.verification}
              setSelectedOption={(newVal) => handleValue('protectionVol.verification', newVal)}
              disabled={formikForm.values.disabled}
            />
            <FormBoldTitle>
              Commentaire
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Votre message"
              rows={3}
              multiline
              name="conformiteDuRisque.protectionVol.commentaire"
              disabled={formikForm.values.disabled}
            />
          </>
      }
      <FormBoldTitle bigger>
        Bail commercial
      </FormBoldTitle>
      <SegmentedButtons
        options={isBcOptions}
        selectedOption={formikForm.values.conformiteDuRisque.bailCommercial?.isBC}
        setSelectedOption={(newVal) => handleValue('bailCommercial.isBC', newVal)}
        disabled={formikForm.values.disabled}
      />
      <FormBoldTitle>
        Commentaire
      </FormBoldTitle>
      <Field
        component={EndTextField}
        placeholder="Votre message"
        rows={3}
        multiline
        name="conformiteDuRisque.bailCommercial.commentaire"
        disabled={formikForm.values.disabled}
      />
      {
        modalInfo?.modalType !== undefined && modalInfo?.label !== undefined && !formikForm.values.disabled &&
          <RiskComplianceCorrectInfoModal
            {...modalInfo}
            selectList={
              (modalInfo.fieldName === 'usage.usage' && riskUsageList) ||
              (modalInfo.fieldName === 'type.type' && riskTypeList) ||
              (modalInfo.fieldName === 'qualiteAssure.qualite' && riskQualiteList) ||
              []
            }
            open={modalInfo.modalType !== undefined}
            handleClose={handleClose}
          />
      }
    </div>
  )
}

export default RiskCompliancePage
