/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import { useCaseSidebar } from 'helpers/hooks/useCaseSidebar'
import { setRouterLastPath } from 'store/slices/routerHistorySlice'
import { useAppDispatch } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import InsertDriveFileRounded from '@mui/icons-material/InsertDriveFileRounded'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'

/* Type imports ------------------------------------------------------------- */
import type { ButtonProps } from '@mui/material'

/* Component declaration ---------------------------------------------------- */
interface GoToCasePageButtonProps extends ButtonProps {
  caseId: string;
}

const GoToCasePageButton: React.FC<GoToCasePageButtonProps> = ({ caseId, ...rest }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const caseSidebar = useCaseSidebar()

  const handleClick = (): void => {
    dispatch(setRouterLastPath(window.location.pathname))
    navigate(`/dossiers/${caseId}`)
    caseSidebar.close()
  }

  return (
    <CustomIconButton
      Icon={InsertDriveFileRounded}
      onClick={handleClick}
      onTouchStart={handleClick}
      {...rest}
    />
  )
}

export default GoToCasePageButton
