/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { handleNumberVerificationAllowEmpty } from 'helpers/numberUtils'

/* Component imports -------------------------------------------------------- */
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import PriceField from 'components/FieldWithInputAdornment/PriceField'
import PriceDiv from 'components/FieldWithInputAdornment/PriceDiv'
import CompensationTableRow from './CompensationTableComponents/CompensationTableRow'
import CompensationTableBoldUppercaseTitle from './CompensationTableComponents/CompensationTableBoldUppercaseTilte'
import CompensationTableBoldSeparator from './CompensationTableComponents/CompensationTableBoldSeparator'
import CompensationPersonTableCard from './CompensationTableComponents/CompensationPersonTableCard'

/* Type imports ------------------------------------------------------------- */
import type {
  Garantie,
  IndemnisationSinapps,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const TableIndemniteSinappsRow = styled(CompensationTableRow)`
  grid-template-columns: repeat(2, 8.5%) 10% repeat(2, 8.5%) 10% repeat(4, 8.5%);
  gap: 1.3%;
`

const Title = styled(FormBoldTitle)`
  margin-top: 10px;
  margin-bottom: -20px;
`

/* Component declaration ---------------------------------------------------- */
interface CompensationPersonSinappsCompensationTableProps {
  personIndexName: string;
  indemnisationsSinapps: IndemnisationSinapps[];
  isHT: boolean;
  disabled: boolean;
  garanties: Garantie[];
  handleValue: (type: string, value: number | '') => void;
}

const CompensationPersonSinappsCompensationTable: React.FC<CompensationPersonSinappsCompensationTableProps> = ({
  personIndexName,
  indemnisationsSinapps,
  isHT,
  disabled,
  garanties,
  handleValue,
}) => {

  return (
    <>
      <FormBoldTitle bigger>
        Récapitulatif de l'indemnisation Sinapps
      </FormBoldTitle>
      <CompensationPersonTableCard>
        <CompensationTableBoldUppercaseTitle>
          <TableIndemniteSinappsRow>
            <div>
              Indem. immédiate
            </div>
            <div>
              Indem. différée
            </div>
            <div>
              Taux de pénalité
            </div>
            <div>
              Montant pénalité immédiate
            </div>
            <div>
              Montant pénalité différée
            </div>
            <div>
              Franchise
            </div>
            <div>
              Montant franchise immédiate
            </div>
            <div>
              Montant franchise différée
            </div>
            <div>
              Indem. immédiate
            </div>
            <div>
              Indem. différée
            </div>
          </TableIndemniteSinappsRow>
        </CompensationTableBoldUppercaseTitle>
        <CompensationTableBoldSeparator />
        {
          indemnisationsSinapps.map((dommage, index) => (
            <div key={`${dommage.garantie?.code}-${index}`}>
              <Title>
                {garanties.find((g) => g.code.code === dommage.garantie?.code)?.code.libelle ?? dommage.garantie?.libelle ?? dommage.garantie?.code}
              </Title>
              <TableIndemniteSinappsRow border={index !== (indemnisationsSinapps?.length || 0) -1}>
                <PriceDiv price={isHT ? dommage.montantIndemniteImmediateHTAvant : dommage.montantIndemniteImmediateTTCAvant} />
                <PriceDiv price={isHT ? dommage.montantIndemniteDiffereeHTAvant : dommage.montantIndemniteDiffereeTTCAvant} />
                <PriceField
                  placeholder="Taux de pénalité"
                  size="small"
                  name={`${personIndexName}.indemnisationsSinapps.[${index}].tauxPenalite`}
                  onChange={(e) => handleValue(`${personIndexName}.indemnisationsSinapps.[${index}].tauxPenalite`, handleNumberVerificationAllowEmpty(e.target.value, 2))}
                  disabled={disabled}
                />
                <PriceDiv price={isHT ? dommage.montantPenaliteImmediateHT : dommage.montantPenaliteImmediateTTC} />
                <PriceDiv price={isHT ? dommage.montantPenaliteDiffereeHT : dommage.montantPenaliteDiffereeTTC} />
                <PriceField
                  placeholder="Montant franchise"
                  size="small"
                  name={`${personIndexName}.indemnisationsSinapps.[${index}].montantFranchise${isHT? 'HT' : 'TTC'}`}
                  onChange={(e) => handleValue(`${personIndexName}.indemnisationsSinapps.[${index}].montantFranchise${isHT? 'HT' : 'TTC'}`, handleNumberVerificationAllowEmpty(e.target.value, 2))}
                  disabled={disabled}
                />
                <PriceDiv price={isHT ? dommage.montantFranchiseImmediateHT : dommage.montantFranchiseImmediateTTC} />
                <PriceDiv price={isHT ? dommage.montantFranchiseDiffereeHT : dommage.montantFranchiseDiffereeTTC} />
                <PriceDiv price={isHT ? dommage.montantIndemniteImmediateHTApres : dommage.montantIndemniteImmediateTTCApres} />
                <PriceDiv price={isHT ? dommage.montantIndemniteDiffereeHTApres : dommage.montantIndemniteDiffereeTTCApres} />
              </TableIndemniteSinappsRow>
            </div>
          ))
        }
        <CompensationTableBoldSeparator />
        <FormBoldTitle smaller>
          <TableIndemniteSinappsRow>
            <PriceDiv price={indemnisationsSinapps.reduce((a, b) => a + Number(isHT ? b.montantIndemniteImmediateHTAvant : b.montantIndemniteImmediateTTCAvant), 0)} />
            <PriceDiv price={indemnisationsSinapps.reduce((a, b) => a + Number(isHT ? b.montantIndemniteDiffereeHTAvant : b.montantIndemniteDiffereeTTCAvant), 0)} />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <PriceDiv price={indemnisationsSinapps.reduce((a, b) => a + Number(isHT ? b.montantIndemniteImmediateHTApres : b.montantIndemniteImmediateTTCApres), 0)} />
            <PriceDiv price={indemnisationsSinapps.reduce((a, b) => a + Number(isHT ? b.montantIndemniteDiffereeHTApres : b.montantIndemniteDiffereeTTCApres), 0)} />
          </TableIndemniteSinappsRow>
        </FormBoldTitle>
      </CompensationPersonTableCard>
    </>
  )
}

export default CompensationPersonSinappsCompensationTable
