/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { usePostPostponeTaskMutation } from 'store/api'
import DateUtils from 'helpers/DateUtils'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { toast } from 'react-toastify'
import CloseButton from 'components/CloseButton/CloseButton'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikHelpers,
  FormikContextType,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  ReporterTachesCreateParams,
  Tache,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const postponeTaskSchema = Yup.object().shape<Shape<ReporterTachesCreateParams>>({
  dateReport: Yup.string().nullable().required('La date est obligatoire'),
  commentaire: Yup.string(),
}).required()

type PostponeTaskRequest = FormikContextType<ReporterTachesCreateParams>

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin-bottom: 20px;
`

const FormButton = styled(SubmitFormButton)`
  margin-left: 0;
`

const CardContainer = styled(Card)`
  margin-bottom: 15px;
  background-color: #F6F9FC;
`

const CardContentContainer = styled(CardContent)`
  padding: 15px;
  padding-top: 0;
`

/* Component declaration ---------------------------------------------------- */
interface TaskMessagePostponeModalProps {
  open: boolean;
  handleClose: () => void;
  task: Tache;
}

const TaskMessagePostponeModal: React.FC<TaskMessagePostponeModalProps> = ({
  open,
  handleClose,
  task,
}) => {

  const [
    submitPostponeTask,
  ] = usePostPostponeTaskMutation()

  const onSubmit = async (values: ReporterTachesCreateParams, { setSubmitting, resetForm }: FormikHelpers<ReporterTachesCreateParams>): Promise<void> => {
    const response = await submitPostponeTask({
      ...values,
      dateReport: DateUtils.dateStringToAPILocalTimeString(values.dateReport),
    })

    if (!isApiError(response)) {
      resetForm()
      handleClose()
    } else {
      toast.error('Une erreur est survenue.')
      setSubmitting(false)
    }
  }

  const formikForm: PostponeTaskRequest = useForm<ReporterTachesCreateParams>(
    {
      initialValues: {
        dateReport: task.prevuPour ?? new Date().toISOString(),
        commentaire: '',
        id: task.id,
        libelle: '',
      },
      onSubmit: onSubmit,
      validationSchema: postponeTaskSchema,
    },
  )

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitleContainer>
        Reporter tâche
        <CloseButton handleClose={handleClose} />
      </DialogTitleContainer>
      <Form form={formikForm}>
        <DialogContent>
          <FormBoldTitle>
            {task.libelle}
            <br />
            Date due :
            {' '}
            {DateUtils.APIStrToLocalDateString(task.prevuPour)}
          </FormBoldTitle>
          <CardContainer>
            <CardContentContainer>
              <FormBoldTitle required>
                Date de report
              </FormBoldTitle>
              <FormikDatePicker name="dateReport" />
              <FormBoldTitle>
                Commentaire
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Votre message"
                name="commentaire"
              />
            </CardContentContainer>
          </CardContainer>
        </DialogContent>
        <DialogActionContainer>
          <FormButton
            variant="contained"
            type="submit"
            disabled={formikForm.isSubmitting}
          >
            {formikForm.isSubmitting ? <CircularProgress /> : 'Reporter'}
          </FormButton>
        </DialogActionContainer>
      </Form>
    </Dialog>
  )
}

export default TaskMessagePostponeModal
