/* Module imports ----------------------------------------------------------- */
import { createTheme } from '@mui/material'
import { frFR } from '@mui/material/locale'

/* Component imports -------------------------------------------------------- */
import SelectArrowIcon from 'components/SelectArrowIcon/SelectArrowIcon'

/* Type imports ------------------------------------------------------------- */
import type { Theme as MuiTheme } from '@mui/material'

/* Material UI theme -------------------------------------------------------- */
const muiTheme = createTheme(
  {
    palette: {
      primary: {
        main: '#3272D1',
      },
      secondary: {
        main: `#0A145F`,
      },
      info: {
        main: `#fff`,
      },
      error: {
        main: '#DC143C',
      },
      success: {
        main: '#32CD32',
      },
    },
    typography: {
      fontFamily: '"Rubik", sans-serif',
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            textTransform: 'none',
            fontWeight: 'bold',
            ...(ownerState.variant === 'outlined' && {
              border: '2px solid',
              backgroundColor: 'white',
              '&:hover': {
                border: '2px solid',
              },
            }),
          }),
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            backgroundColor: 'white',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          multiple: {
            backgroundColor: 'white',
            whiteSpace: 'break-spaces',
          },
        },
        defaultProps: {
          IconComponent: SelectArrowIcon,
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: 'white',
            width: '100%',
          },
          notchedOutline: {
            borderColor: '#E2E8F1',
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          fontFamily: `"Rubik", sans-serif`,
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            border: '1px solid #E2E8F1 !important',
            boxShadow: 'none',
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: '15px',
            ':last-child': {
              padding: '15px',
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent !important',
            width: '100%',
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            height: 6,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            '&.Mui-selected': {
              fontWeight: 'bold',
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            cursor: 'pointer !important',
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            maxHeight: '50vh',
          },
        },
      },
    },
  },
  frFR,
)

/* Custom theme properties -------------------------------------------------- */
const additionalThemeProps = {
  colors: {
    grey: '#E2E8F1',
    lightgrey: '#F6F8FD',
    darkgrey: '#808080',
    black: '#252525',
    main: '#FFFFFF',
  },
  media: {
    mobile: {
      main: 'screen and (max-width: 480px), screen and (min-width: 481px) and (max-width: 1024px) and (max-height: 480px)',
      portrait: 'screen and (max-width: 480px)',
      landscape: 'screen and (min-width: 481px) and (max-width: 1024px) and (max-height: 480px)',
    },
    tablet: 'screen and (min-width: 481px) and (max-width: 1024px) and (min-height: 480px)',
    desktop: 'screen and (min-width: 1025px)',
  },
  layoutPadding: {
    main: '10px 0.5rem 0px',
    desktop: '0px max(calc(15vw - 200px), 0.5rem) 0px',
  },
}

export type AppTheme = MuiTheme & typeof additionalThemeProps

/* Emotion.js theme --------------------------------------------------------- */
export const emotionTheme: AppTheme = {
  ...muiTheme,
  ...additionalThemeProps,
}
