/* Module imports ----------------------------------------------------------- */
import { useAppSelector } from 'store/hooks'
import { selectNetwork } from 'store/slices/networkSlice'

/* Hook declarations -------------------------------------------------------- */
export const useIsConnected = (): boolean => {
  const networkState = useAppSelector(selectNetwork)

  return networkState.hasConnection
}
