/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import DateUtils from 'helpers/DateUtils'
import {
  useGetPlanningQuery,
  useGetPlanningTypesQuery,
  usePostAddGeneralActionMutation,
  usePostEditGeneralActionMutation,
} from 'store/api'
import { isValidString } from 'helpers/isValidString'
import {
  useAppDispatch,
  useAppSelector,
  useAuthInfo,
} from 'store/hooks'
import { useIsConnected } from 'helpers/hooks/useIsConnected'
import {
  getPlanningState,
  setPlanningStartDate,
  setPlanningViewType,
} from 'store/slices/planningSlice'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
} from '@mui/material'
import { toast } from 'react-toastify'
import PageContainer from 'layouts/PageContainer/PageContainer'
import HeaderWithSearchAndButton from 'layouts/MainLayout/Headers/HeaderWithSearchAndButton'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import Loader from 'components/Loader/Loader'
import PlanningEventList from 'pages/PlanningPages/PlanningComponents/PlanningEventList'
import PlanningCalendar from './PlanningCalendar/PlanningCalendar'
import PlanningEventHandlerModal from './PlanningEventHandlerModal/PlanningEventHandlerModal'
import PlanningFilters from '../PlanningComponents/PlanningFilters'
import PlanningCalendarButtons from '../PlanningComponents/PlanningCalendarButtons'

/* Type imports ------------------------------------------------------------- */
import type FullCalendar from '@fullcalendar/react'
import type {
  Planning,
  PlanningActionGeneraleRequest,
  TypeRdv,
} from 'API/__generated__/Api'
import { TypeRdvType } from 'API/__generated__/Api'
import type {
  EventHandlerActionType,
  EventHandlerType,
} from 'types/EventHandler'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import { CalendarViewTypes } from 'helpers/FullCalendarOptions'
import type { ApiResponse } from 'helpers/fetchHelpers'

/* Internal variables ------------------------------------------------------- */
const CalendarViewTypeFrenchMapping: Record<string, string> = {
  timeGridDay: 'Jour',
  timeGridWeek: 'Semaine',
  dayGridMonth: 'Mois',
}

const sInitialCalendarView: string = 'dayGridMonth'

/* Styled components -------------------------------------------------------- */
const CardContentContainer = styled.div`
  padding: 10px 10px 5px;
`

const SegmentedButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  margin-bottom: 5px;
  gap: 10px;

  @media ${(props) => props.theme.media.tablet} {
    grid-template-columns: auto auto;
    grid-template-areas:
      "first first"
      "second second";

    div:first-of-type {
      grid-area: first;
    }
  }

  @media ${(props) => props.theme.media.mobile.main} {
    display: flex;
    flex-direction: column;
  }
`

const ViewOnMapButton = styled(Button)`
  height: 100%;
  margin-left: auto;

  @media ${(props) => props.theme.media.mobile.main} {
    width: 100%;
  }
`

/* Component declaration ---------------------------------------------------- */
interface PlanningPageProps {}

const PlanningPage: React.FC<PlanningPageProps> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const authInfo = useAuthInfo()
  const isConnected = useIsConnected()
  const planningState = useAppSelector(getPlanningState)
  const calendarRef: React.RefObject<FullCalendar> = useRef<FullCalendar>(null)
  const [ events, setEvents ] = useState<Planning[]>([])
  const [ sortedTypes, setSortedTypes ] = useState<TypeRdv[]>([])
  const [ selectedFilters, setSelectedFilters ] = useState<string[]>([])
  const [ eventHandler, setEventHandler ] = useState<EventHandlerType>({ open: false, action: null, event: undefined, dates: null })

  const {
    currentData: currentPlanningData = [],
    isFetching: isFetchingPlanningData,
  } = useGetPlanningQuery(
    {
      expert: authInfo?.currentCollaborateur?.refAnnuaire.refComplete || '',
      dateDebut: DateUtils.planningStartDateApi,
      dateFin: DateUtils.planningEndDateApi,
    },
    { skip: !isValidString(authInfo?.currentCollaborateur?.refAnnuaire.refComplete) },
  )
  const {
    currentData: currentTypes = [],
    isFetching: isFetchingTypes,
  } = useGetPlanningTypesQuery()
  const [
    submitAddGeneralAction,
    { isLoading: isLoadingAddGeneralAction },
  ] = usePostAddGeneralActionMutation()
  const [
    submitEditGeneralAction,
    { isLoading: isLoadingEditGeneralAction },
  ] = usePostEditGeneralActionMutation()

  const eventHandlerTypes: TypeRdv[] = sortedTypes.filter((sortedType) => sortedType.type === TypeRdvType.ActionGenerale)

  useEffect(() => {
    let lCompIsMounted: boolean = true

    if (calendarRef.current && lCompIsMounted) {
      if (planningState?.viewType) {
        calendarRef.current.getApi().changeView(planningState.viewType)
      }
    }

    return (): void => {
      lCompIsMounted = false
    }
  }, [ calendarRef ])

  useEffect(() => {
    if (!isFetchingPlanningData)
      setEvents(currentPlanningData)
  }, [ isFetchingPlanningData ])

  useEffect(() => {
    if (!isFetchingTypes) {
      const types = [ ...currentTypes ].sort((a, b) => b.type.localeCompare(a.type))
      setSortedTypes(types)
    }
  }, [ isFetchingTypes ])

  const updateCalendarView = (): void => {
    if (calendarRef.current) {
      dispatch(setPlanningStartDate(calendarRef.current.getApi().view.currentStart.toISOString()))
    }
  }

  const changeCalendarView = (pViewType: string): void => {
    if (calendarRef.current) {
      dispatch(setPlanningViewType(pViewType as CalendarViewTypes))
      calendarRef.current.getApi().changeView(pViewType)
      updateCalendarView()
    }
  }

  const calendarViewTypeToFrench = (pViewType?: string): string | undefined => {
    if (pViewType !== undefined) {
      return CalendarViewTypeFrenchMapping[pViewType]
    }

    return undefined
  }

  const onPreviousClicked: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().prev()
      updateCalendarView()
    }
  }

  const onNextClicked: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().next()
      updateCalendarView()
    }
  }

  const onTodayClicked: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().today()
      updateCalendarView()
    }
  }

  const onDateClicked = (value: Date | null) => {
    if (calendarRef.current && value) {
      calendarRef.current.getApi().gotoDate(value)
      updateCalendarView()
    }
  }

  const onCalendarOptionClick = (pOption: string): void => {
    const lKey = Object.keys(CalendarViewTypeFrenchMapping).find((pKey) => CalendarViewTypeFrenchMapping[pKey] === pOption)

    if (lKey !== undefined) {
      changeCalendarView(lKey)
    }
  }

  const onEventChange = (type?: EventHandlerActionType | null, newEvent?: Planning): void => {
    setEventHandler({ open: false, action: null, event: undefined, dates: null })

    const onFinish = (response: ApiResponse<void | string>): void => {
      if (isApiError(response)) {
        toast.error(`Une erreur est survenue lors de ${type === 'add' ? "l'ajout" : 'la modification'} de l'action générale.`)
      }
    }

    if (newEvent !== undefined && newEvent?.id !== undefined) {
      const data: PlanningActionGeneraleRequest = {
        collaborateur: authInfo?.currentCollaborateur?.refAnnuaire.refComplete || '',
        dateDebut: DateUtils.dateStringToAPILocalTimeString(newEvent.dateDebut),
        dateFin: DateUtils.dateStringToAPILocalTimeString(newEvent.dateFin),
        typeRdv: newEvent.typeRDV.code,
        commentaire: newEvent.commentaire,
        journeeComplete: newEvent.journeeComplete || false,
      }
      if (type === 'edit') {
        submitEditGeneralAction({ id: newEvent.id, data }).then(onFinish).catch(console.error)
        setEvents([ ...events.filter((e) => e.id !== newEvent?.id), newEvent ])
      }
      if (type === 'add') {
        submitAddGeneralAction({ data }).then(onFinish).catch(console.error)
        setEvents([ ...events, newEvent ])
      }
    }
  }

  const filterEvents = (event: Planning): boolean => {
    if (calendarRef.current) {
      const { activeStart, activeEnd } = calendarRef.current.getApi().view
      const startDate: Date = DateUtils.APIStrToDate(event.dateDebut)
      const endDate: Date = DateUtils.APIStrToDate(event.dateFin)

      return startDate >= activeStart && endDate <= activeEnd &&
      (!selectedFilters?.length || selectedFilters.includes(event.typeRDV?.code))
    }
    return false
  }

  const filteredEvents: Planning[] = events.filter((e: Planning): boolean => filterEvents(e))

  const calendarOptions: SegmentedButtonOption<string>[] = Object.values(CalendarViewTypeFrenchMapping).map((option) => ({ value: option }))

  return (
    <>
      {(isFetchingPlanningData || isLoadingAddGeneralAction || isLoadingEditGeneralAction) && <Loader />}
      {
        eventHandler.open && (
          <PlanningEventHandlerModal
            handleClose={onEventChange}
            types={eventHandlerTypes}
            {...eventHandler}
          />
        )
      }
      <HeaderWithSearchAndButton
        onClick={() => setEventHandler({ open: true, action: 'add', event: undefined, dates: null })}
        text="Nouveau rdv"
        title="Agenda"
        disableButton={isFetchingPlanningData}
      />
      <PageContainer>
        <Card>
          <CardContentContainer>
            <SegmentedButtonsContainer>
              <SegmentedButtons
                options={calendarOptions}
                selectedOption={calendarViewTypeToFrench(calendarRef.current?.getApi().view.type)}
                setSelectedOption={onCalendarOptionClick}
                smaller
              />
              <PlanningCalendarButtons
                calendarViewTitle={calendarRef.current?.getApi()?.view.title || ''}
                onPreviousClicked={onPreviousClicked}
                onTodayClicked={onTodayClicked}
                onNextClicked={onNextClicked}
                dateToClick={calendarRef.current?.getApi().view.currentStart ?? null}
                onDateClicked={onDateClicked}
              />
              <ViewOnMapButton
                variant="contained"
                onClick={() => navigate('/planning/carte')}
                disabled={!isConnected}
              >
                Voir sur la carte
              </ViewOnMapButton>
            </SegmentedButtonsContainer>
            <PlanningFilters
              filters={sortedTypes}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
          </CardContentContainer>
        </Card>
        <PlanningCalendar
          calendarRef={calendarRef}
          initialView={planningState?.viewType ?? sInitialCalendarView}
          initialDate={planningState?.startDate ? new Date(planningState?.startDate) : new Date()}
          events={filteredEvents}
          setEventHandler={setEventHandler}
          onEventChange={onEventChange}
        />
        {
          calendarRef.current?.getApi()?.view?.type !== CalendarViewTypes.Day && (
            <PlanningEventList
              events={filteredEvents}
              periodType={calendarViewTypeToFrench(calendarRef.current?.getApi().view.type)}
            />
          )
        }
      </PageContainer>
    </>
  )
}

export default PlanningPage
