/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Type imports ------------------------------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store/store'

/* Type declarations -------------------------------------------------------- */
export interface QuickActionModalState {
  quickActionsOpen: boolean;
  caseId: string | null;
}

/* Redux slice -------------------------------------------------------------- */
const quickActionModalSlice = createSlice(
  {
    name: 'modal',
    initialState: {
      quickActionsOpen: false,
      caseId: null,
    } as QuickActionModalState,
    reducers: {
      openQuickActions: (state, { payload }: PayloadAction<QuickActionModalState>) => {
        state.quickActionsOpen = payload.quickActionsOpen
        state.caseId = payload.caseId
      },
      closeQuickActions: (state) => {
        state.quickActionsOpen = false
        state.caseId = null
      },
      toggleQuickActions: (state) => {
        state.quickActionsOpen = !state.quickActionsOpen
      },
      setQuickActionsOpenState: (state, { payload }: PayloadAction<boolean>) => {
        state.quickActionsOpen = payload
      },
    },
  },
)

/* Export slice components -------------------------------------------------- */
export const {
  openQuickActions,
  closeQuickActions,
  toggleQuickActions,
  setQuickActionsOpenState,
} = quickActionModalSlice.actions

export default quickActionModalSlice.reducer

export const selectQuickActionModal = (state: RootState): QuickActionModalState => {
  return state.quickActionModal
}
