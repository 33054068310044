/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetCaseDocumentsQuery,
  useGetSinappsDocumentTypeListQuery,
  useGetSinappsReportTodoListQuery,
  usePostSinappsReportMutation,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'
import DateUtils from 'helpers/DateUtils'
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  Checkbox,
  MenuItem,
  Select,
} from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { toast } from 'react-toastify'
import PageContainer from 'layouts/PageContainer/PageContainer'
import HeaderAction from 'layouts/MainLayout/Headers/HeadersComponents/HeaderAction'
import Loader from 'components/Loader/Loader'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CheckableButton from 'components/CheckableButton/CheckableButton'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikHelpers,
  FormikContextType,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  CommandeDeposerCompteRenduRequest,
  DocumentAvecTypeRequest,
  PieceJointe,
  TypeDocumentSinapps,
  TypeDocumentSinappsEnumLabel,
} from 'API/__generated__/Api'
import { TypePJ } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
interface SinappsReportCommentRequest {
  isCommentMandatory: boolean;
}

type SinappsReportRequest = SinappsReportCommentRequest & CommandeDeposerCompteRenduRequest

const sinappsReportSchema = Yup.object().shape<Shape<SinappsReportRequest>>({
  resteAFaire: Yup.array(Yup.string().required()).min(1, 'Merci de sélectionner au moins une option').required(),
  commentaire: Yup.string().when('isCommentMandatory', {
    is: true,
    then: (schema) => schema.required('Le commentaire est obligatoire'),
  }),
  documents: Yup.array(Yup.mixed<DocumentAvecTypeRequest>().required()),
}).required()

type SinappsReportForm = FormikContextType<SinappsReportRequest>

/* Styled components -------------------------------------------------------- */
interface ErrorField {
  error?: boolean;
}
const GridContainer = styled.div<ErrorField>`
  display: grid;

  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  border: ${(props) => props.error ? '1px solid #d32f2f' : undefined};
  border-radius: 4px;

  align-items: stretch;
  justify-content: stretch;

  margin-bottom: 10px;
`

const BigCheckableButton = styled(CheckableButton)`
  height: 80px;
`

const TableCard = styled(Card)`
  font-size: 14px;
  padding: 0px 20px;
  margin: 10px 0px 20px;
`

const TableBoldUppercaseTitle = styled(FormBoldTitle)`
  text-transform: uppercase;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
`

interface TableRowProps {
  border?: boolean;
}

const TableRow = styled.div<TableRowProps>`
  height: 100%;
  display: grid;
  gap: 10px;
  padding: 5px 0px;
  justify-content: stretch;
  align-items: center;
  border-bottom: ${(props) => props.border ? `1px solid ${props.theme.colors.grey}` : undefined};
  grid-template-columns: repeat(2, 1fr) 3fr 1fr auto;
  width: 100%;
`

const FileTableRow = styled(TableRow)`
  grid-template-columns: 40px repeat(5, 1fr);
`

const TableBoldSeparator = styled.div`
  width: calc(100% + 40px);
  margin-left: -20px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

/* Component declaration ---------------------------------------------------- */
interface SinappsReportPageProps {}

const SinappsReportPage: React.FC<SinappsReportPageProps> = () => {
  const { caseId = '' } = useParams<{caseId: string}>()
  const navigate = useNavigate()

  const {
    currentData: todoList = [],
    isFetching: isFetchingTodoList,
  } = useGetSinappsReportTodoListQuery()
  const {
    currentData: documents = [],
    isFetching: isFetchingDocuments,
  } = useGetCaseDocumentsQuery({ dossier: caseId })
  const {
    currentData: documentTypeList = [],
    isFetching: isFetchingDocumentTypeList,
  } = useGetSinappsDocumentTypeListQuery()
  const [
    submitSinappsReport,
  ] = usePostSinappsReportMutation()

  const onSubmit = async (values: SinappsReportRequest, { setSubmitting, resetForm }: FormikHelpers<SinappsReportRequest>): Promise<void> => {
    const response = await submitSinappsReport({
      caseId,
      data: values,
    })

    if (!isApiError(response)) {
      resetForm()
      navigate(-1)
    } else {
      toast.error('Une erreur est survenue lors de l‘envoi du rapport Sinapps. Veuillez vérifier le formulaire.')
      setSubmitting(false)
    }
  }

  const formikForm: SinappsReportForm = useForm<SinappsReportRequest>(
    {
      initialValues: {
        commentaire: '',
        resteAFaire: [],
        documents: [],
        isCommentMandatory: false,
      },
      onSubmit: onSubmit,
      validationSchema: sinappsReportSchema,
    },
  )

  const handleValue = (type: string, value?: string | string[] | DocumentAvecTypeRequest[]): void => {
    formikForm.setFieldValue(type, value)
  }

  const handleCheckedButton = (value: string, checked: boolean): void => {
    const newValues: string[] | undefined = [ ...(formikForm.values.resteAFaire || []) ]

    if (!checked) {
      handleValue('resteAFaire', newValues?.filter((val) => val !== value))
    }
    else {
      newValues?.push(value)
      handleValue('resteAFaire', newValues)
    }
  }

  useEffect(() => {
    if (formikForm.values.resteAFaire.some((formTodo) => todoList.find((todoType) => todoType.codeResteAFaire.code === formTodo)?.commentaireObligatoire)) {
      formikForm.setFieldValue('isCommentMandatory', true)
    } else {
      formikForm.setFieldValue('isCommentMandatory', false)
    }

  }, [ formikForm.values.resteAFaire ])

  const documentList = useMemo(() => [ ...documents || [] ].filter((file: PieceJointe) => file.type === TypePJ.Doc || file.type === TypePJ.DocEvenementiel), [ documents ])

  const handleFileCheckAll = (): void => {
    if (formikForm.values.documents?.length === documentList.length && formikForm.values.documents.length > 0) {
      handleValue('documents', [])
    } else {
      handleValue('documents', documentList.map((doc): DocumentAvecTypeRequest => ({ idFichier: doc.id || '', type: doc.categorieSinapps?.code })))
    }
  }

  const handleFileChecked = (fileId: string, fileType?: TypeDocumentSinappsEnumLabel): void => {
    if (formikForm.values.documents?.some((doc) => doc.idFichier === fileId)) {
      handleValue('documents', [ ...formikForm.values.documents.filter((doc) => doc.idFichier !== fileId) ])
    } else {
      handleValue('documents', [ ...formikForm.values.documents || [], { idFichier: fileId, type: fileType?.code } ])
    }
  }

  const modifyFileType = (fileId: string, fileType: string): void => {
    handleValue('documents', [ ...(formikForm.values.documents || []).filter((doc) => doc.idFichier !== fileId), { idFichier: fileId, type: fileType as TypeDocumentSinapps } ])
  }

  return (
    <>
      <HeaderAction
        title="Compte rendu"
        onSubmit={formikForm.handleSubmit}
      >
        <SubmitFormButton
          type="submit"
          variant="contained"
          disabled={formikForm.isSubmitting || isFetchingTodoList}
        >
          Valider
        </SubmitFormButton>
      </HeaderAction>
      <PageContainer>
        {(formikForm.isSubmitting || isFetchingTodoList || isFetchingDocuments) && <Loader />}
        <Form form={formikForm}>
          <FormBoldTitle />
          <GridContainer error={formikForm.touched.resteAFaire !== undefined && formikForm.errors.resteAFaire !== undefined}>
            {
              todoList.map((todoType, index) => (
                <BigCheckableButton
                  key={`${todoType.codeResteAFaire.code}-${index}`}
                  checked={formikForm.values.resteAFaire.some((to) => to === todoType.codeResteAFaire.code)}
                  onChange={(e, c): void => handleCheckedButton(todoType.codeResteAFaire.code, c)}
                  label={todoType.codeResteAFaire.libelle}
                />
              ))
            }
          </GridContainer>
          <ErrorMessage name="resteAFaire" />
          <FormBoldTitle required={formikForm.values.isCommentMandatory}>
            Commentaire
          </FormBoldTitle>
          <Field
            component={TextField}
            placeholder="Votre message"
            name="commentaire"
            rows={3}
            multiline
          />
          <FormBoldTitle>
            Joindre des documents
          </FormBoldTitle>
          <TableCard>
            <TableBoldUppercaseTitle>
              <FileTableRow>
                <Checkbox
                  onChange={handleFileCheckAll}
                  checked={formikForm.values.documents?.length === documentList.length && documentList.length > 0}
                />
                <div>
                  Libellé
                </div>
                <div>
                  Date de création
                </div>
                <div>
                  Nom Document
                </div>
                <div>
                  Type
                </div>
                <div>
                  Origine
                </div>
              </FileTableRow>
            </TableBoldUppercaseTitle>
            <TableBoldSeparator />
            {
              documentList.map((document, index) => (
                <FileTableRow
                  border={index !== (documentList.length || 0) -1}
                  key={`${document.id}-${index}`}
                >
                  <Checkbox
                    checked={formikForm.values.documents?.some((doc) => doc.idFichier === document.id)}
                    onChange={() => handleFileChecked(document.id ?? document.libelle, document.categorieSinapps)}
                  />
                  <div>
                    {document.libelle}
                  </div>
                  <div>
                    {DateUtils.APIStrToLocalDateString(document.dateCreation)}
                  </div>
                  <div>
                    {document.fileName}
                  </div>
                  {
                    isValidString(document.categorieSinapps?.code) &&
                      <Select
                        value={formikForm.values.documents?.find((doc) => doc.idFichier === document.id)?.type ?? document.categorieSinapps?.code}
                        onChange={(e): void => modifyFileType(document.id ?? document.libelle, e.target.value)}
                        fullWidth
                        displayEmpty
                        size="small"
                        disabled={isFetchingDocumentTypeList}
                      >
                        {
                          documentTypeList.map(({ code, libelle }) => (
                            <MenuItem
                              value={code}
                              key={code}
                            >
                              {libelle}
                            </MenuItem>
                          ))
                        }
                      </Select>
                  }
                  <div>
                    {document.origine}
                  </div>
                </FileTableRow>
              ))
            }
          </TableCard>
        </Form>
      </PageContainer>
    </>
  )
}

export default SinappsReportPage
