/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetCaseEventDocumentsQuery,
  useGetEventDocumentsFamilleActionListQuery,
  useGetEventDocumentsRecipientListQuery,
  useLazyGetCaseEventDocumentsQuery,
  usePostNewEventDocumentMutation,
} from 'store/api'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'
import { isValidString } from 'helpers/isValidString'
import { useAppDispatch } from 'store/hooks'
import {
  setAttachment,
  setCourriers,
} from 'store/slices/courrierSlice'
import { useIsConnected } from 'helpers/hooks/useIsConnected'
import { isApiResponse } from 'helpers/fetchHelpers'
import { getAddress } from 'helpers/getAddress'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@mui/material'
import { Field } from 'formik'
import { Select } from 'formik-mui'
import { toast } from 'react-toastify'
import PageContainer from 'layouts/PageContainer/PageContainer'
import HeaderAction from 'layouts/MainLayout/Headers/HeadersComponents/HeaderAction'
import Loader from 'components/Loader/Loader'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CheckableButton from 'components/CheckableButton/CheckableButton'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import TravelerLargeTitle from 'components/TravelerLargeTitle/TravelerLargeTitle'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'
import Tabs from 'components/Tabs/Tabs'
import DocumentList from './DocumentList'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type { ApiResponse } from 'helpers/fetchHelpers'
import { DocumentTypes } from 'types/Document'
import type {
  DataDocument,
  EvenementielDestinataire,
} from 'API/__generated__/Api'
import {
  TypeEnregistrementCourrier,
  TypeEvenementiel,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
interface CourrierProperties {
  codeFamille: string;
  codeAction: string;
  destinataires: string[];
}

interface CourrierRequest {
  courriers: CourrierProperties[];
}

const courrierSchema = Yup.object().shape<Shape<CourrierRequest>>({
  courriers: Yup.array(Yup.object().shape<Shape<CourrierProperties>>({
    codeAction: Yup.string().required('Le type de courrier est obligatoire'),
    codeFamille: Yup.string().required('La famille est obligatoire'),
    destinataires: Yup.array(Yup.string().required()).min(1, 'Il doit y avoir au moins un destinataire').required(),
  })).required(),
}).required()

type NewCourrierForm = FormikContextType<CourrierRequest>

/* Styled components -------------------------------------------------------- */
const NewCourrier = styled(SubmitFormButton)`
  margin-left: auto;
`

const CardContainer = styled(Card)`
  margin-top: 20px;
  margin-bottom: 20px;
  border: solid 2px ${(props) => props.theme.palette.primary.main} !important;
  padding: 20px;
`

const CourrierTitleContainer = styled.div`
  font-weight: bold;
  color: ${(props) => props.theme.palette.primary.main};
`

const FamilleActionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 10px));
  gap: 20px;
`

const BigCheckableButton = styled(CheckableButton)`
  height: 100%;
`

const GridContainer = styled.div`
  display: grid;
  gap: 10px;
  align-items: stretch;
  justify-content: stretch;
  margin-bottom: 10px;
`

interface ErrorField {
  error?: boolean;
}

const TwoGridContainer = styled(GridContainer)<ErrorField>`
  grid-template-columns: repeat(2, 1fr);
  @media ${(props) => props.theme.media.mobile.main} {
    grid-template-columns: 1fr;
  }
  border: ${(props) => props.error ? '1px solid #d32f2f' : undefined};
  border-radius: 4px;
`

const HeaderTabs = styled(Tabs)`
  margin-bottom: -13px;

  button {
    @media ${(props) => props.theme.media.desktop} {
      font-size: 1.1rem;
    }
  }
`

const DialogTitleContainer = styled(DialogTitle)`
  display: flex;
  justify-content: center;
`

const DialogContentContainer = styled(DialogContent)`
  display: flex;
  gap: 10px;
  justify-content: center;
`

/* Component declaration ---------------------------------------------------- */
interface QaCourrierPageProps {}

const QaCourrierPage: React.FC<QaCourrierPageProps> = () => {
  const isConnected = useIsConnected()
  const { caseId = '' } = useParams<{caseId: string}>()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [ tabValue, setTabValue ] = useState<number>(0)
  const [ openAfterSaveModal, setOpenAfterSaveModal ] = useState<boolean>(false)
  const [ idsForSending, setIdsForSending ] = useState<string[]>([])

  const initialCourrier: CourrierProperties = {
    codeFamille: '',
    codeAction: '',
    destinataires: [],
  }

  const {
    currentData: eventDocuments = [],
    isFetching: isFetchingEventDocuments,
  } = useGetCaseEventDocumentsQuery({ dossier: caseId, avecBrouillons: true })
  const {
    currentData: familleActionList = [],
    isFetching: isFetchingFamilleActionList,
  } = useGetEventDocumentsFamilleActionListQuery({ dossier: caseId, filtreEvenementiel: TypeEvenementiel.Dossier })
  const {
    currentData: recipientList = [],
    isFetching: isFetchingRecipientList,
  } = useGetEventDocumentsRecipientListQuery(caseId)
  const [
    submitNewDocument,
  ] = usePostNewEventDocumentMutation()
  const [ getDocuments ] = useLazyGetCaseEventDocumentsQuery()

  const formikForm: NewCourrierForm = useForm<CourrierRequest>(
    {
      initialValues: {
        courriers: [ initialCourrier ],
      },
      validationSchema: courrierSchema,
    },
  )

  const sendCourriers = async (courriers: CourrierProperties[], saveType: TypeEnregistrementCourrier) => {
    const findSaveType = (codeAction: string) => {
      const code = familleActionList.flatMap((la) => la.actions).find((la) => la?.code === codeAction)?.logiciel.code
      if (code === '3') return TypeEnregistrementCourrier.Definitif
      return saveType
    }

    const allData: DataDocument[] = []
    for (let index = 0; index < courriers.length; index++) {
      for (let jindex = 0; jindex < courriers[index].destinataires.length; jindex++) {
        const response: ApiResponse<DataDocument> = await submitNewDocument({
          dossier: caseId,
          data: {
            codeAction: courriers[index].codeAction,
            compteurSequence: 0,
            destinataire: { id: courriers[index].destinataires[jindex] },
            typeEnregistrement: findSaveType(courriers[index].codeAction),
          },
        })
        const recipient = recipientList.find((rec) => rec.id === courriers[index].destinataires[jindex])?.nom
        if (isApiResponse<DataDocument>(response)) {
          switch (findSaveType(courriers[index].codeAction)) {
            case TypeEnregistrementCourrier.Definitif:
              toast.success(`Le courrier ${index + 1} pour le destinataire ${recipient} à bien été enregistré en définitif.`)
              setIdsForSending([ ...idsForSending, response.data.docId ])
              break
            case TypeEnregistrementCourrier.Frappe:
              toast.success(`Le courrier ${index + 1} pour le destinataire ${recipient} à bien été enregistré en brouillon.`)
              break
            default:
              allData.push(response.data)
              break
          }
        } else {
          toast.error(`Une erreur est survenue lors du chargement du courrier ${index + 1} pour le destinataire ${recipient}.`)
        }
      }
    }
    if (saveType === TypeEnregistrementCourrier.Visualisation && allData.length > 0) {
      dispatch(setCourriers(allData))
      navigate(`/dossiers/${caseId}/traveller/actions/document`)
    } else {
      formikForm.resetForm()
    }
  }

  useEffect(() => {
    if (idsForSending.length === formikForm.values.courriers.length) {
      setOpenAfterSaveModal(true)
    }
  }, [ idsForSending ])

  const onSubmit = (saveType: TypeEnregistrementCourrier) => {
    formikForm.submitForm().catch(console.error)
    formikForm.validateForm()
      .then((errors) => {
        if (!errors.courriers) {
          return sendCourriers(formikForm.values.courriers, saveType)
        } else {
          console.log('new courrier errors', errors)
        }
      })
      .catch(console.error)
      .finally(() => formikForm.setSubmitting(false))
  }

  const handleValue = (type: string, value?: string | string[]): void => {
    formikForm.setFieldValue(type, value)
  }

  const handleCheckedRecipientButton = (value: string, index: number, checked: boolean): void => {
    const newValues = [ ...(formikForm.values.courriers[index].destinataires || []) ]

    if (!checked) {
      handleValue(`courriers[${index}].destinataires`, newValues.filter((val) => val !== value))
    }
    else {
      newValues.push(value)
      handleValue(`courriers[${index}].destinataires`, newValues)
    }
  }

  const addCourrier = () => {
    formikForm.setValues({ courriers: [ ...formikForm.values.courriers, initialCourrier ]})
  }

  const leaveClick = () => {
    setOpenAfterSaveModal(false)
    navigate(-1)
  }

  const sendClick = async () => {
    setOpenAfterSaveModal(false)
    const documents = (await getDocuments({ dossier: caseId })).data
    if (documents?.length) {
      dispatch(setAttachment(documents.filter(((doc) => idsForSending.find((id) => id === doc.idDocEvenementiel)))))
      navigate(`/dossiers/${caseId}/traveller/actions/communication`)
    }
  }

  const isLoading = useMemo(() => isFetchingFamilleActionList || isFetchingRecipientList || isFetchingEventDocuments || formikForm.isSubmitting,
    [
      isFetchingFamilleActionList,
      isFetchingEventDocuments,
      isFetchingRecipientList,
      formikForm.isSubmitting,
    ])

  return (
    <>
      <HeaderAction
        title={
          <HeaderTabs
            value={tabValue}
            onChange={(e, v) => setTabValue(v)}
            tabs={[ 'Liste des courriers', 'Nouveau courrier' ]}
          />
        }
        onSubmit={formikForm.handleSubmit}
      >
        {
          tabValue === 1 &&
            <>
              <SubmitFormButton
                variant="outlined"
                disabled={isLoading}
                onClick={() => onSubmit(TypeEnregistrementCourrier.Definitif)}
              >
                Enregistrer en définitif
              </SubmitFormButton>
              <SubmitFormButton
                variant="outlined"
                disabled={isLoading}
                onClick={() => onSubmit(TypeEnregistrementCourrier.Frappe)}
              >
                Enregistrer en brouillon
              </SubmitFormButton>
              <SubmitFormButton
                variant="contained"
                disabled={isLoading || !isConnected}
                onClick={() => onSubmit(TypeEnregistrementCourrier.Visualisation)}
              >
                Visualiser
              </SubmitFormButton>
            </>
        }
      </HeaderAction>
      <PageContainer>
        {isLoading && <Loader />}
        {
          tabValue === 0 &&
          [ ...eventDocuments ]
            .filter((value) => !DocumentTypes.some((type) => type.code === value.familleAction?.code))
            .sort((a, b) => b.dateCreation?.localeCompare(a.dateCreation || '') || 0)
            .map((value, index) => (
              <DocumentList
                key={`${value.id}-${index}`}
                document={value}
                caseId={caseId}
                isConnected={isConnected}
              />
            ))
        }
        {
          tabValue === 1 &&
            <Form form={formikForm}>
              <TravelerLargeTitle>
                Courriers
                <NewCourrier
                  variant="outlined"
                  disabled={isLoading}
                  onClick={addCourrier}
                >
                  Ajouter courrier
                </NewCourrier>
              </TravelerLargeTitle>
              {
                formikForm.values.courriers.map((courrier, index) => (
                  <div key={`${courrier.codeAction}-${index}`}>
                    <CardContainer>
                      <CourrierTitleContainer>
                        {`Courrier ${index + 1}`}
                      </CourrierTitleContainer>
                      <FamilleActionContainer>
                        <div>
                          <FormBoldTitle required>
                            Famille
                          </FormBoldTitle>
                          <Field
                            component={Select}
                            name={`courriers[${index}].codeFamille`}
                            displayEmpty
                            renderValue={verifySelectFieldValue(formikForm.values.courriers[index].codeFamille)}
                            onChange={() => handleValue(`courriers[${index}].codeAction`, '')}
                          >
                            {
                              familleActionList.map((value, index) => (
                                <MenuItem
                                  value={value.code}
                                  key={`${value.code}-${index}`}
                                >
                                  {value.libelle}
                                </MenuItem>
                              ))
                            }
                          </Field>
                        </div>
                        <div>
                          <FormBoldTitle required>
                            Type de courrier
                          </FormBoldTitle>
                          <Field
                            component={Select}
                            name={`courriers[${index}].codeAction`}
                            displayEmpty
                            renderValue={verifySelectFieldValue(formikForm.values.courriers[index].codeAction)}
                            disabled={!isValidString(formikForm.values.courriers[index].codeFamille)}
                          >
                            {
                              familleActionList.find((famille) => formikForm.values.courriers[index].codeFamille === famille.code)?.actions
                                ?.filter((value) => value.logiciel.code !== '3')
                                ?.map((value, index) => (
                                  <MenuItem
                                    value={value.code}
                                    key={`${value.code}-${index}`}
                                  >
                                    {value.libelle}
                                  </MenuItem>
                                ))
                            }
                          </Field>
                        </div>
                      </FamilleActionContainer>
                      <div>
                        <FormBoldTitle required>
                          Destinataire(s)
                        </FormBoldTitle>
                        <TwoGridContainer error={formikForm.touched.courriers?.[index]?.destinataires !== undefined && (formikForm.errors.courriers?.[index] as unknown as CourrierProperties)?.destinataires !== undefined}>
                          {
                            recipientList
                              .map((value: EvenementielDestinataire, recipientIndex) => (
                                <BigCheckableButton
                                  key={`${value.id}-${recipientIndex}`}
                                  checked={formikForm.values.courriers[index].destinataires.some((dest) => dest === value.id)}
                                  onChange={(e, c): void => handleCheckedRecipientButton(value.id, index, c)}
                                  label={
                                    (
                                      <>
                                        <b>
                                          {value.role}
                                        </b>
                                        <div>
                                          <div>
                                            {value.nom}
                                          </div>
                                          <div>
                                            {getAddress(value.adresse)}
                                          </div>
                                        </div>
                                      </>
                                    )
                                  }
                                />
                              ))
                          }
                        </TwoGridContainer>
                        <ErrorMessage name={`courriers[${index}].destinataires`} />
                      </div>
                    </CardContainer>
                  </div>
                ))
              }
            </Form>
        }
        {
          openAfterSaveModal &&
            <Dialog
              open
              maxWidth="xs"
              fullWidth
            >
              <DialogTitleContainer>
                Voulez-vous envoyez les documents ?
              </DialogTitleContainer>
              <DialogContentContainer>
                <SubmitFormButton
                  onClick={sendClick}
                  variant="contained"
                >
                  Envoyer
                </SubmitFormButton>
                <SubmitFormButton
                  onClick={leaveClick}
                  variant="outlined"
                >
                  Quitter
                </SubmitFormButton>
              </DialogContentContainer>
            </Dialog>
        }
      </PageContainer>
    </>
  )
}

export default QaCourrierPage
