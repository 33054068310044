/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'
import { getAddress } from 'helpers/getAddress'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import CloseButton from 'components/CloseButton/CloseButton'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'

/* Type imports ------------------------------------------------------------- */
import type {
  RiskComplianceModalType,
  RiskComplianceValueType,
} from 'types/RiskCompliance'
import type {
  Adresse,
  CodeLabel,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin-bottom: 20px;
`

const GridContainer = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);

  align-items: center;
  justify-content: stretch;

  margin-bottom: 10px;
`

const TwoGridNoMarginContainer = styled(GridContainer)`
  margin-bottom: 0px;
`

const FormButton = styled(SubmitFormButton)`
  margin-left: 0;
`

const CardContainer = styled(Card)`
  margin-bottom: 15px;
  background-color: #F6F9FC;
`

const CardContentContainer = styled(CardContent)`
  padding: 15px;
  padding-top: 0;
`

/* Component declaration ---------------------------------------------------- */
interface RiskComplianceCorrectInfoModalProps {
  open: boolean;
  handleClose: (value?: RiskComplianceValueType) => void;
  label: string;
  value: RiskComplianceValueType;
  modalType: RiskComplianceModalType;
  selectList: CodeLabel[];
}

const RiskComplianceCorrectInfoModal: React.FC<RiskComplianceCorrectInfoModalProps> = ({
  open,
  handleClose,
  label,
  value,
  modalType,
  selectList,
}) => {
  const [ address, setAddress ] = useState<Adresse>(value as Adresse)
  const [ selectedOption, setSelectedOption ] = useState<string>((value as CodeLabel)?.code || '')
  const [ valueRelevee, setValueRelevee ] = useState<RiskComplianceValueType>(value)

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitleContainer>
        Corriger les informations ?
        <CloseButton handleClose={() => handleClose()} />
      </DialogTitleContainer>
      {
        modalType === 'address' && (
          <DialogContentContainer>
            <FormBoldTitle>
              {label}
              {' : '}
              {getAddress(value as Adresse)}
            </FormBoldTitle>
            <CardContainer>
              <CardContentContainer>
                <FormBoldTitle>
                  {label}
                  {' :'}
                </FormBoldTitle>
                <TextField
                  value={address.adresse1}
                  onChange={(e): void => setAddress({ ...address, adresse1: e.target.value })}
                  placeholder="Adresse"
                />
                <br />
                <br />
                <TextField
                  value={address.adresse2}
                  onChange={(e): void => setAddress({ ...address, adresse2: e.target.value })}
                  placeholder="Adresse"
                />
                <br />
                <br />
                <TextField
                  value={address.adresse3}
                  onChange={(e): void => setAddress({ ...address, adresse3: e.target.value })}
                  placeholder="Adresse"
                />
                <TwoGridNoMarginContainer>
                  <FormBoldTitle>
                    Code postal :
                  </FormBoldTitle>
                  <FormBoldTitle>
                    Ville :
                  </FormBoldTitle>
                </TwoGridNoMarginContainer>
                <GridContainer>
                  <TextField
                    value={address.codePostal}
                    onChange={(e): void => setAddress({ ...address, codePostal: e.target.value })}
                    placeholder="Code postal"
                  />
                  <TextField
                    value={address.ville}
                    onChange={(e): void => setAddress({ ...address, ville: e.target.value })}
                    placeholder="Ville"
                  />
                </GridContainer>
                <TwoGridNoMarginContainer>
                  <FormBoldTitle>
                    Pays :
                  </FormBoldTitle>
                  <FormBoldTitle>
                    Digicode :
                  </FormBoldTitle>
                </TwoGridNoMarginContainer>
                <GridContainer>
                  <TextField
                    value={address.pays}
                    onChange={(e): void => setAddress({ ...address, pays: e.target.value })}
                    placeholder="Pays"
                  />
                  <TextField
                    value={address.digicode}
                    onChange={(e): void => setAddress({ ...address, digicode: e.target.value })}
                    placeholder="Digicode"
                  />
                </GridContainer>
              </CardContentContainer>
            </CardContainer>
          </DialogContentContainer>
        )
      }
      {
        modalType === 'selectList' && (
          <DialogContentContainer>
            <FormBoldTitle>
              {label}
              {' : '}
              {(value as CodeLabel)?.libelle}
            </FormBoldTitle>
            <CardContainer>
              <CardContentContainer>
                <FormBoldTitle>
                  {label}
                  {' :'}
                </FormBoldTitle>
                <Select
                  value={selectedOption}
                  onChange={(e): void => setSelectedOption(e.target.value)}
                  fullWidth
                  renderValue={verifySelectFieldValue(selectedOption)}
                >
                  {
                    selectList.map((option) => (
                      <MenuItem
                        value={option.code}
                        key={option.code}
                      >
                        {option.libelle}
                      </MenuItem>
                    ))
                  }
                </Select>
              </CardContentContainer>
            </CardContainer>
          </DialogContentContainer>
        )
      }
      {
        modalType === 'verification' && (
          <DialogContentContainer>
            <FormBoldTitle>
              {label}
              {' déclaré(e) : '}
              {JSON.stringify(value)}
            </FormBoldTitle>
            <CardContainer>
              <CardContentContainer>
                <FormBoldTitle>
                  {label}
                  {' mesuré(e) :'}
                </FormBoldTitle>
                <TextField
                  value={valueRelevee}
                  onChange={(e): void => setValueRelevee(e.target.value)}
                  type="number"
                  placeholder={label}
                  InputProps={
                    {
                      inputProps: {
                        style: { textAlign: 'right' },
                        min: 0,
                      },
                    }
                  }
                />
              </CardContentContainer>
            </CardContainer>
          </DialogContentContainer>
        )
      }
      <DialogActionContainer>
        <FormButton onClick={() => handleClose()}>
          Annuler
        </FormButton>
        <FormButton
          variant="contained"
          disabled={
            (modalType === 'address' && !Object.values(address).some((v, index) => v !== Object.values(value)[index])) ||
            (modalType === 'selectList' && selectedOption === (value as CodeLabel)?.code) ||
            (modalType === 'verification' && (JSON.stringify(valueRelevee) === JSON.stringify(value) || JSON.stringify(valueRelevee) === ''))
          }
          onClick={
            () => handleClose(
              (modalType === 'address' && address) ||
              (modalType === 'selectList' && selectList.find((o) => o.code === selectedOption)) ||
              valueRelevee,
            )
          }
        >
          Corriger
        </FormButton>
      </DialogActionContainer>
    </Dialog>
  )
}

export default RiskComplianceCorrectInfoModal
